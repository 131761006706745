import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import DayHoraire from '../DayHoraire'
import { ORDER_TYPE_DELIVERY } from '../../../constants'
import { sortOpenHours } from './Horaires.services'
import { sortSlots } from '../../../utils'
import { useAppSelector } from '../../../redux/hook'

function Horaires({ hours }) {
  const { customerAddress } = useAppSelector(state => state.userReducer);
  const { orderType } = useAppSelector(state => state.pendingOrderReducer);
  const renderHours = () => {
    if (hours) {
      const sortedOpenHours = sortOpenHours(hours);
      return (
        <tbody>
          {_.map(sortedOpenHours, (openHour, key) => {
            const { name, slots, slotsDelivery } = openHour;
            const selectedSlots =
              customerAddress && orderType === ORDER_TYPE_DELIVERY && slotsDelivery
                ? slotsDelivery
                : slots;
            const sortedSlots = sortSlots(selectedSlots, moment(key));
            return (
              <DayHoraire
                name={name}
                slots={sortedSlots}
                key={name}
                displayTop={sortedOpenHours[0] !== openHour}
              />
            );
          })}
        </tbody>
      );
    }
  };
  return (
    <div>
      <table className='table dark-modal' style={{ margin: 'auto', width: '80%' }}>
        {renderHours()}
      </table>
    </div>
  );

}



export default Horaires;
