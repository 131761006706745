import { ORDER_TYPE_CLICK_AND_COLLECT, ORDER_TYPE_CLICK_AND_SEAT, ORDER_TYPE_DELIVERY } from '../../constants';
import { getAddressFormatted } from '../../utils';
import store from '../../redux/store';
import { ShopState } from '../../1_types/genericTypes';

export const getAddress = () => {
  const { address: customerAddress, orderType } = store.getState().pendingOrderReducer;
  const { addressShop } = store.getState().shopReducer as Partial<ShopState>;
  let address =
        customerAddress && orderType === ORDER_TYPE_DELIVERY
          ? customerAddress
          : addressShop;
  const nbCaract = 25;
  if (address) {
    address = getAddressFormatted(address);
    address = `${address.substring(0, nbCaract)}${address.length > nbCaract ? '...' : ''}`;
  } else {
    address = 'Aucune adresse';
  }
  return address;
};

export const getOrderTypeLabel = (orderType, isMobile) => {
  switch (orderType) {
    case ORDER_TYPE_DELIVERY :
      return !isMobile ? 'Commande en Livraison' : 'En Livraison';
    case ORDER_TYPE_CLICK_AND_COLLECT :
      return !isMobile ? 'Commande à Emporter' : 'A Emporter';
    case ORDER_TYPE_CLICK_AND_SEAT :
      return !isMobile ? 'Commande sur Place' : 'Sur Place';
    default : return '';
  }
}
