import store from '../redux/store';
import {sendPostRequest} from './backend';
import {API_VALIDATE_ADDRESS_DELIVERY} from '../constants';
import {sendCloudWatchLogs} from '../utils';

export const checkDeliveryAddress = async (customerAddress: any, shopId: string) => {
    if (!customerAddress) {
        throw new Error('No address given');
    }
    const {name} = store.getState().configurationReducer;
    const {user = {}} = store.getState().userReducer;
    try {
        sendCloudWatchLogs(
            `Checking address with stuart and Uber Direct : ${JSON.stringify(customerAddress)}`
        );
        const data = {
            customerAddress,
            customerPhone: user.numero,
            shopId
        };
        const headers = {};
        const response = await sendPostRequest(API_VALIDATE_ADDRESS_DELIVERY, data, headers);
        if (response?.data?.success) {
            sendCloudWatchLogs('Address validated with Stuart/Uber Direct.');
            return true;
        }
        return false;
    } catch (error) {
        let errorMessage = error;
        sendCloudWatchLogs(`Could not validate address with stuart/Uber Direct: ${errorMessage}`);
        if (errorMessage === 'OUT_OF_RANGE' && customerAddress?.city) {
            errorMessage = `${name} n'a pas encore été déployé sur ${(customerAddress.city)}.`;
        }
        throw errorMessage;
    }
};

type ShopConfiguration = {
    stuart: { enabled: boolean },
    uberDirect: { enabled: boolean }
}

export const isStuartEnabled = () => {
    const {shopReducer = {}, configurationReducer = {}} = store.getState();
    const globalStuart = configurationReducer['stuart'] ?? false;
    const shopConfiguration: ShopConfiguration = shopReducer['shopConfiguration'] ?? {};
    const shopStuart = shopConfiguration?.stuart?.enabled;
    return (typeof shopStuart === 'boolean') ? shopStuart : globalStuart;
}
export const isUberDirectEnabled = () => {
    const {shopReducer = {}} = store.getState();
    return (shopReducer['shopConfiguration'] as ShopConfiguration)?.uberDirect?.enabled;
}