import { Cookies } from 'react-cookie';
import _ from 'lodash';
import moment from 'moment';
import apiService from '@dishopsaas/dishop-backend-api-service'
import store from '../redux/store';
import { resetPendingOrder, showAddressModal, updateClosestShops, updateCustomerAddressCheck } from '../redux/actions';
import { getFirebaseCustomerId } from '../api/firebase/firebaseAuthentication';
import { sendCloudWatchAlert, sendCloudWatchLogs } from './logs';
import { sendPostRequest } from '../api/backend'
import {
    API_REQUEST_DELIVERY_PRICING,
    COOKIE_ADDRESS,
    COOKIE_ORDER_TYPE,
    COOKIE_SHOP_ID,
    ORDER_TYPE_DELIVERY
} from '../constants';
import { getClosestShops, getShopAndZoneData } from './shop';
import { listenShopData } from '../api/firebase/firebaseOn';
import { dispatchDataFromCookies, setPendingOrderCookies } from './cookies';
import { checkDeliveryAddress } from '../api';
import { CUSTOMER_ADDRESS_CHECK_VALUES } from '../redux/reducers/componentReducer';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete/dist';
import { getAddressDetails, getAddressFormatted, parseAddress } from './map';
import Geocode from 'react-geocode/lib';
import { UPDATE_PENDING_ORDER } from '../redux/actions/types';

export const getCustomerAddress = async (customerId: string, withShopId = false): Promise<any> => {
    const pendingOrder = store.getState().pendingOrderReducer || await apiService.pendingOrdersGetOne([customerId]);
    const customerAddress = pendingOrder?.address;
    return withShopId ? { customerAddress, shopId: pendingOrder?.shopId } : customerAddress;
};

export const generateCustomerAddress = (address: any): any => {
    const newAddress = _.cloneDeep(address);
    const { latitude, longitude } = newAddress.location;
    newAddress.id = `${parseFloat(latitude).toFixed(6)}${parseFloat(longitude).toFixed(6)}`
        .replace(/\./g, '');
    newAddress.selected = true;
    return newAddress;
};

export const removeCustomerPendingOrder = async (): Promise<void> => {
    const cookies = new Cookies();
    cookies.remove(COOKIE_ADDRESS);
    cookies.remove(COOKIE_SHOP_ID);
    cookies.remove(COOKIE_ORDER_TYPE);
    const customerId = getFirebaseCustomerId();
    if (customerId) {
        sendCloudWatchLogs(`Removing pendingOrder of customer ${customerId}`);
        await apiService.pendingOrdersDelete([customerId])
    }
    store.dispatch(resetPendingOrder());
};

export const setUserShopIds = async (shopId: string, customerId: string) => {
    // Add shops to firebase
    try {
        const shopIds = store.getState()?.userReducer?.user?.shopIds;
        if (customerId && shopId) {
            const foundShopId = _.find(shopIds, shopIdTemp => {
                return shopIdTemp.shopId === shopId;
            });
            if (!foundShopId) {
                const shopIdData = {
                    shopId,
                    creationTime: moment().format()
                };
                await apiService.usersUpsert([customerId, 'shopIds', shopId], shopIdData)
            }
        }
    } catch (error) {
        sendCloudWatchAlert(`Error setting user shop ids ${error}`);
    }
};

export const setUserPendingOrderAddressAndOrderTypeFirebase = async (
    customerId: string,
    shopId: string,
    address: any,
    orderType: string
): Promise<void> => {
    try {
        const { isUserAnonymous } = store.getState().userReducer as any;
        await apiService.pendingOrdersUpsert([customerId], {
            address,
            shopId,
            orderType
        })
        if (!isUserAnonymous && shopId) {
            await setUserShopIds(shopId, customerId);
        }
    } catch (error) {
        await sendCloudWatchAlert(`Eerror setting user pending order ${error}`);
        throw error;
    }
};

export const updateAddressAndShop = async (shopId: string, newAddress: any, orderType: string): Promise<void> => {
    try {
        const { userConnected, user } = store.getState().userReducer;
        const { delivery } = store.getState().configurationReducer;
        const { shopConfiguration = {} } = store.getState().shopReducer as any;
        const { delivery: deliveryShop = {} } = shopConfiguration;
        const { customerId } = user;
        if (newAddress) {
            newAddress = generateCustomerAddress(newAddress);
        }
        if (
            orderType === ORDER_TYPE_DELIVERY &&
            (delivery?.userServicePrice || deliveryShop?.userServicePrice) &&
            shopId
        ) {
            const data = {
                shopId,
                customerLocation: newAddress.location
            };
            const response = await sendPostRequest(API_REQUEST_DELIVERY_PRICING, data, {});
            const { customerFee, deliveryPricing } = response.data;
            sendCloudWatchLogs(`Getting delivery price ${customerFee}`);
            newAddress.deliveryPrice = customerFee;
            newAddress.pricing = deliveryPricing;
        }
        if (userConnected) {
            await setUserPendingOrderAddressAndOrderTypeFirebase(
                customerId,
                shopId,
                newAddress,
                orderType
            );
            if (store.getState()?.configurationReducer?.marketplace?.enabled) {
                await listenShopData(null, shopId, newAddress, getShopAndZoneData, orderType);
            }
        } else {
            await listenShopData(null, shopId, newAddress, getShopAndZoneData, orderType);
            setPendingOrderCookies(!!newAddress, newAddress, shopId, orderType);
            dispatchDataFromCookies();
        }
    } catch (error) {
        sendCloudWatchAlert(`Error updating address and shop ${error}`);
        throw error;
    }
};
export const getDeliverableShops = async (address: any, shops: any, getShopsIsloading?: boolean, setGetShopsIsloadingLoading?: any, handleCatchError?: any): Promise<any[]> => {
    try {
        const deliverableShops = await Promise.all(
            shops?.map(async (shop: any) => {
                if (shop?.configuration?.uberDirect?.enabled || shop?.configuration?.stuart?.enabled) {
                    if (getShopsIsloading === false) {
                        setGetShopsIsloadingLoading(true)
                    }
                    return await checkDeliveryAddress(address, shop?.shopId) ? shop : null
                }
                return shop
            })
        );
        return deliverableShops?.filter((shop: any) => !!shop);
    } catch (error) {
        if (handleCatchError) {
            await handleCatchError(error);
        }
        return [];
    }
}

export const checkIfCustomerAddressValid = async (customerAddress: any, orderType: string, shopId: string) => {
    const customerAddressCheck = store.getState().componentReducer?.customerAddressCheck;
    const shopIsLoaded = (store.getState().shopReducer as any)?.shopIsLoaded;
    const marketplace = (store.getState().configurationReducer as any)?.marketplace;
    if (marketplace?.enabled) {
        store.dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.VALID));
        return;
    }
    if (
        customerAddressCheck === CUSTOMER_ADDRESS_CHECK_VALUES.VALID ||
        customerAddressCheck === CUSTOMER_ADDRESS_CHECK_VALUES.PENDING ||
        !shopIsLoaded ||
        !orderType
    ) {
        return;
    }
    if (!customerAddress) {
        store.dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.VALID));
        return;
    }
    if (orderType !== ORDER_TYPE_DELIVERY) {
        store.dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.VALID));
        return
    }
    store.dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.PENDING));

    const foundPlaces = await (
        customerAddress?.placeId
            ? geocodeByPlaceId(customerAddress.placeId)
            : geocodeByAddress(getAddressFormatted(customerAddress))
    );
    if (!foundPlaces?.length) {
        store.dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.INVALID));
        store.dispatch(showAddressModal(true));
        return;
    }
    const firstFoundPlace = foundPlaces[0];
    const foundAddress = parseAddress(firstFoundPlace?.address_components)
    const { lat, lng } = await getLatLng(firstFoundPlace);
    const addresses = await Geocode.fromLatLng(lat, lng);
    const { district, subLocality } = getAddressDetails(addresses.results);
    foundAddress.district = district;
    foundAddress.subLocality = subLocality;
    foundAddress.location = {
        latitude: lat,
        longitude: lng
    };
    foundAddress.placeId = firstFoundPlace?.place_id;
    const shops = await getClosestShops(foundAddress, orderType);
    const deliverableShops = await getDeliverableShops(foundAddress, shops);
    store.dispatch(updateClosestShops(deliverableShops));
    if (!deliverableShops?.length) {
        store.dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.INVALID));
        store.dispatch(showAddressModal(true));
    } else {
        store.dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.VALID));
        const isDeliverableShopsHaveCurrentShopId = !!deliverableShops.find(shop => shop?.shopId === shopId);
        if (!customerAddress?.placeId) {
            customerAddress.placeId = foundAddress.placeId;
        }
        const pendingOrderShopId = isDeliverableShopsHaveCurrentShopId
            ? shopId
            : deliverableShops[0]?.shopId;
        store.dispatch({ type: UPDATE_PENDING_ORDER, payload: { shopId: pendingOrderShopId, address: customerAddress } });
    }
};