import apiService from '@dishopsaas/dishop-backend-api-service'
import { FETCH_CONFIGURATION } from './types'
import { initializeBackend, initializeFacebook, initializeGoogle, initializeLocalize } from '../../api'
import { ORDER_TYPE_CLICK_AND_COLLECT, ORDER_TYPE_CLICK_AND_SEAT, ORDER_TYPE_DELIVERY } from '../../constants'

export const fetchConfiguration = () => async dispatch => {
  const configVal = await apiService.configurationGetOne()
  const { domain, google, localize, facebook } = configVal;
  initializeFacebook(facebook || {});
  initializeGoogle(google || {});
  initializeBackend(domain);
  initializeLocalize(localize || {});
  const orderTypesPriority = [
    ORDER_TYPE_DELIVERY,
    ORDER_TYPE_CLICK_AND_COLLECT,
    ORDER_TYPE_CLICK_AND_SEAT
  ];
  configVal.orderTypes = orderTypesPriority.filter(type => configVal?.orderTypes?.includes(type));
  dispatch({ type: FETCH_CONFIGURATION, payload: configVal });
};
