import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { connect } from 'react-redux';
import StarsIcon from '@mui/icons-material/Stars';
import {
  addLoyalty,
  getLoyalty,
  getCustomerLoyaltyPoints,
  getLoyaltyPercentage
} from './Loyalty.services';
import { LOYALTY, PRIMARY, WHITE } from '../../constants';
import styles from './Loyalty.style';
import Button from '../Button';
import { displayPriceFormat, isStringNotNull } from '../../utils';
import Spinner from '../Spinner';

const INITIAL_STATE = {
  border: 'transparent',
  promoCode: '',
  loadingPromotion: false
};
class Loyalty extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  render() {
    const { user, secondaryColor, loyalty, loyaltyShop, shopId, totalProductPrice } = this.props;
    const { newLoyalty, isShopLoyalty } = getLoyalty(loyaltyShop, loyalty);
    const setloyalty = newLoyalty;
    const userPoints = getCustomerLoyaltyPoints(
      user.userShopIds,
      isShopLoyalty ? shopId : null,
      user.points
    );
    const { loadingPromotion } = this.state;
    const { loyaltyButton } = styles(secondaryColor);
    let classProgress = 'col-12 mt-2';
    if (userPoints >= setloyalty.limit) {
      classProgress = 'col-7 col-sm-9 align-self-center';
    }
    return (
      <>
        <style type='text/css'>
          {`
            .dishop-iso .progress-bar
             {
               background-color: ${secondaryColor} ;
             }
          `}
        </style>
        {setloyalty && (
          <div className='row mb-3'>
            <div className='col-12'>
              <StarsIcon style={styles(secondaryColor).starsIcon} className='mr-2 mb-1' />
              <b>
                Fidélité : {displayPriceFormat(setloyalty.limit * setloyalty.point, null)} offerts
                pour {displayPriceFormat(setloyalty.limit, null)} d'achat
              </b>
            </div>

            <div className='col-12'>
              <div style={{ display: 'flex' }}>
                <div className={classProgress}>
                  <ProgressBar
                    striped
                    animated
                    style={styles(secondaryColor).progressBar}
                    label={
                      isStringNotNull(userPoints)
                        ? `${getLoyaltyPercentage(userPoints, setloyalty)}%`
                        : '0%'
                    }
                    max={setloyalty.limit}
                    now={isStringNotNull(userPoints) ? userPoints : '0'}
                  />
                </div>
                {userPoints >= setloyalty.limit && (
                  <div className='col-5 col-sm-3 justify-content-end input-group-append ml-auto'>
                    <Button
                      type={PRIMARY}
                      className='col-10 d-flex align-items-center justify-content-center'
                      style={loyaltyButton}
                      onClick={() => {
                        addLoyalty(this, LOYALTY, totalProductPrice);
                      }}
                    >
                      {loadingPromotion && <Spinner color={ WHITE } size='sm' />}
                      {!loadingPromotion && (
                        <div>
                          <span>Utiliser</span>
                        </div>
                      )}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ configurationReducer, userReducer, shopReducer }) => {
  const { secondaryColor, loyalty, name, companyId } = configurationReducer;
  const { loyalty: loyaltyShop, shopId } = shopReducer;
  const { user, shopIds } = userReducer;
  return {
    secondaryColor,
    loyalty,
    user,
    customerId: user.customerId,
    name,
    loyaltyShop,
    companyId,
    shopId,
    shopIds
  };
};

export default connect(mapStateToProps, {})(Loyalty);
