import React from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCart'
import UpdateIcon from '@mui/icons-material/Update'
import '../../css/react-tabs.css'
import Spinner from '../Spinner'
import { TEXT_CANCEL_ORDER } from '../../constants/text'
import {
  CONTACTING_IMAGE_URL,
  COOKING_IMAGE_URL,
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_PREPARING,
  ORDER_PHASE_TAKEAWAY,
  ORDER_PHASE_WAITER,
  ORDER_TYPE_DELIVERY,
  WAITER_IMAGE_URL
} from '../../constants'
import { getAddressFormatted, getDayFormat, getEstimationTime, getShopFromOrder, getStatus } from '../../utils'

import OrderSteps from '../OrderSteps'
import { showMessageModal } from '../../redux/actions'
import { cancelOrder } from './OrderStatus.services'

import MapClient from '../MapClient'
import Order from '../Order'

const INITIAL_STATE = {
  estimationTime: ''
};

class OrderStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  renderTabs = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    return (
      <Tabs>
        <TabList>
          <Tab style={{ width: '45%' }}>
            <div className='row justify-content-center'>
              <UpdateIcon className='mr-2' />
              <p className='mb-0 d-none d-sm-block'>Statut</p>
            </div>
          </Tab>
          <Tab style={{ width: '45%' }}>
            <div className='row justify-content-center'>
              <ShoppingCartOutlinedIcon className='mr-2' />
              <p className='mb-0 d-none d-sm-block'>Commande</p>
            </div>
          </Tab>
        </TabList>

        <TabPanel>
          <div>
            {this.renderStatus()}
            {this.renderAddress()}
            {this.renderFooter()}
          </div>
        </TabPanel>
        <TabPanel>
          <div className='text-left' style={{ paddingTop: '16px', marginBottom: '16px' }}>
            <Order order={order} showCart showFees />
          </div>
        </TabPanel>
      </Tabs>
    );
  };

  renderAddress = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    const { address, orderType } = order;
    const shop = getShopFromOrder(order);
    const { address: addressShop } = shop;
    return (
      <div>
        <p className='text-muted mb-2 label'>
          {orderType === ORDER_TYPE_DELIVERY && (
            <>
              Adresse de livraison :<h5>{getAddressFormatted(address)}</h5>
            </>
          )}
          {orderType !== ORDER_TYPE_DELIVERY && (
            <>
              Adresse du commerce :<h5>{getAddressFormatted(addressShop)}</h5>
            </>
          )}
        </p>
      </div>
    );
  };

  renderEstimationTime = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    const { orderType, isSchedule, date } = order;
    const estimationTime = getEstimationTime(order);
    let estimationDay = '';
    if (isSchedule) {
      estimationDay = getDayFormat(date);
    }
    return (
      <p className='text-muted mb-2 label'>
        {orderType !== ORDER_TYPE_DELIVERY
          ? `Votre commande est prévue pour ${estimationDay && 'le '}:`
          : `Votre livraison est prévue pour ${estimationDay && 'le '}:`}
        <h5 className='no-localization label'>{`${estimationDay} ${estimationTime}`}</h5>
      </p>
    );
  };

  renderStatus = () => {
    const { chargeId, orders } = this.props;
    const order = orders[chargeId];
    const { status } = order;
    const shop = getShopFromOrder(order);
    const { address: addressShop } = shop;
    if (!_.isEmpty(order)) {
      switch (status) {
        case ORDER_PHASE_CONTACTING: {
          return (
            <>
              <OrderSteps order={order} activeStep='1' />
              <div>
                {this.renderEstimationTime()}
                <p className='text-muted mb-0 label'>{getStatus(order)}</p>
              </div>
              <img
                style={{ height: '160px', width: '240px' }}
                className='img-fluid rounded mx-auto mb-3'
                alt='contacting gif'
                src={CONTACTING_IMAGE_URL}
              />
            </>
          );
        }
        case ORDER_PHASE_PREPARING: {
          return (
            <>
              <OrderSteps order={order} activeStep='2' />
              {this.renderEstimationTime()}
              <p className='text-muted mb-0 label'>{getStatus(order)}</p>
              <img
                style={{ height: '150px', width: '300px' }}
                className='img-fluid rounded mx-auto d-block my-5'
                alt='cooking gif'
                src={COOKING_IMAGE_URL}
              />
            </>
          );
        }
        case ORDER_PHASE_DELIVERING: {
          return (
            <>
              <OrderSteps order={order} activeStep='3' />
              {this.renderEstimationTime()}
              <div className='my-4'>
                <MapClient chargeId={chargeId} />
              </div>
            </>
          );
        }
        case ORDER_PHASE_TAKEAWAY: {
          return (
            <>
              <OrderSteps order={order} activeStep='3' />
              <p className='text-muted mb-2 label'>
                Votre commande est prête à être récupérée au :
              </p>
              <h5 className='text-muted mb-0 label'>{getAddressFormatted(addressShop)}</h5>
              <div className='my-4'>
                <MapClient chargeId={chargeId} />
              </div>
            </>
          );
        }
        case ORDER_PHASE_WAITER: {
          return (
            <>
              <OrderSteps order={order} activeStep='3' />
              <p className='text-muted mb-0 label'>{getStatus(order)}</p>
              <img
                style={{ height: '300px', width: '300px' }}
                className='img-fluid rounded mx-auto my-4'
                alt='waiter gif'
                src={WAITER_IMAGE_URL}
              />
            </>
          );
        }
        default:
          return (
            <>
              <OrderSteps order={order} activeStep='1' />
              <img
                style={{ height: '160px', width: '240px' }}
                className='img-fluid rounded mx-auto my-4'
                alt='contacting gif'
                src={CONTACTING_IMAGE_URL}
              />
            </>
          );
      }
    }
  };

  renderFooter = () => {
    const {
      orders,
      chargeId,
      showMessageModal,
      secondaryColor,
      support,
      shops,
      disabledCancel,
      marketPlace
    } = this.props;
    const order = orders[chargeId];
    const { status } = order;
    const marketPlaceEnabled = marketPlace?.enabled;
    let number = support?.number;
    if (marketPlaceEnabled) {
      const foundShop = _.find(shops, (shop, id) => id === order.shopId);
      number = foundShop?.support?.number;
    }
    const phone = order.numeroDep ? order.numeroDep : number;
    if (!_.isEmpty(order)) {
      switch (status) {
        case ORDER_PHASE_CONTACTING: {
          return (
            <>
              {!disabledCancel && (
                <div
                  className='button-bottom-modal mt-2'
                  onClick={() => {
                    showMessageModal(TEXT_CANCEL_ORDER, {
                      onClick: () => cancelOrder(this, order),
                      showCancel: true
                    });
                  }}
                >
                  <i className='fas fa-times-circle fa-2x' style={{ color: secondaryColor }} />
                  <p className='text-muted'>Annuler la commande</p>
                </div>
              )}
            </>
          );
        }
        case ORDER_PHASE_DELIVERING: {
          return (
            <>
              <div className='button-bottom-modal mt-4' onClick={() => window.open(`tel:${phone}`)}>
                <p className='text-muted mb-0 label'>Contactez le livreur en cas de problème</p>
                <p className='text-muted'>{phone}</p>
              </div>
            </>
          );
        }
        default:
          break;
      }
    }
  };

  render() {
    const { orders, chargeId } = this.props;
    const order = orders[chargeId];
    if (!_.isEmpty(order)) {
      return (
        <div className='row'>
          <div className='container-fluid text-center'>{this.renderTabs()}</div>
        </div>
      );
    }
    return (
      <div className='row justify-content-center'>
        <Spinner />
      </div>
    );
  }
}

const mapStateToProps = ({ configurationReducer, shopReducer, orderReducer }) => {
  const { support, shops } = shopReducer;
  const { secondaryColor, name, cancel = {}, marketPlace } = configurationReducer;
  const { orders } = orderReducer;
  const { disabled: disabledCancel } = cancel;
  return {
    secondaryColor,
    support,
    shops,
    name,
    orders,
    disabledCancel,
    marketPlace
  };
};

export default connect(mapStateToProps, { showMessageModal })(OrderStatus);