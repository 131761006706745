import store from '../../redux/store'
import { showMessageModal } from '../../redux/actions'
import { sendCloudWatchAlert } from '../../utils/logs'
import { createCustomerBackend } from '../backend'
import { getFirebaseAuth } from './firebaseInit'
import { backendV2auth } from '../backend-v2'
import { tryCatch } from 'topkat-utils'

//----------------------------------------
// FIREBASE AUTHENTICATION SERVICES
//----------------------------------------
export const getFirebaseCustomerId = () => {
  const { currentUser } = getFirebaseAuth() || {}
  return currentUser ? currentUser.uid : null;
}

const lastTokenDate = Date.now()
const tokenRefreshTimeMs = 10 * 60 * 1000

export const getIdToken = async (forceRefresh = false) => {
  if (lastTokenDate < Date.now() - tokenRefreshTimeMs) forceRefresh = true
  const idToken = await getFirebaseAuth()?.currentUser?.getIdToken(forceRefresh)
  console.log(`backendV2auth`, idToken)
  backendV2auth(idToken)
  return idToken
}


// FORCE refresh token at least once per hour, that's the firebase token life
// => https://firebase.google.com/docs/auth/admin/manage-sessions
setInterval(() => {
  tryCatch(() => {
    console.log(`Refreshing token`)
    getIdToken(true)
  })
}, tokenRefreshTimeMs)

export const signOut = async () => {
  await getFirebaseAuth().signOut();
}

export const signInUser = async (email, password) => {
  try {
    const response = await getFirebaseAuth().signInWithEmailAndPassword(email, password);
    return response.user.uid;
  } catch (error) {
    const errorMessage = String(error);
    if (
      !errorMessage.includes('already')
      && !errorMessage.includes('6 characters')
      && !errorMessage.includes('user does not have a password')
      && !errorMessage.includes('There is no user record')
    ) {
      sendCloudWatchAlert(`Error login ${errorMessage}`);
    }
    const errorCode = error.code;
    throw errorCode;
  }
}

export const deleteUser = async () => {
  // TODO delete user also in backend. But this should be a
  // backend transaction to avoid coherence issues
  const user = getFirebaseAuth().currentUser;
  if (user) {
    try {
      sendCloudWatchAlert(`Delete user ${user.uid}`);
      await user.delete();
    } catch (error) {
      sendCloudWatchAlert(`Could not delete user ${error}`);
      throw error;
    }
  }
};

export const signUpUser = async (email, password, lastName, firstName, numero, phoneObj, newsletter) => {
  try {
    const response = await getFirebaseAuth().createUserWithEmailAndPassword(email, password);
    const customerId = response.user.uid;
    const profile = {
      firstName,
      lastName,
      email,
      numero,
      phone: phoneObj,
      firstOrder: true,
      newsletter,
      id: customerId
    };
    await createCustomerBackend(profile);
    const { uid } = response.user;
    return uid;
  } catch (error) {
    const errorMessage = String(error);
    sendCloudWatchAlert(`Error sign up ${errorMessage}`);
    const errorCode = error.code;
    throw errorCode;
  }
};

export const forgetPasswordUser = async email => {
  getFirebaseAuth().useDeviceLanguage();
  try {
    await getFirebaseAuth().sendPasswordResetEmail(email);
    store.dispatch(
      showMessageModal(
        'Un email vous a été envoyé, cliquez sur le lien dans celui-ci pour réinitialiser votre mot de passe'
      )
    );
  } catch (error) {
    const errorCode = error.code;
    throw errorCode;
  }
};

export const ckeckUserAccount = async email => {
  try {
    const data = await getFirebaseAuth().fetchSignInMethodsForEmail(email);
    return data;
  } catch (error) {
    sendCloudWatchAlert(`Error fetching user with email ${error}`);
    throw error;
  }
};