import moment from 'moment';
import momentTimezone from 'moment-timezone';
import { getCurrentLanguage } from './config';

export const getDateToday = (date, timezone) => {
  const formatDay = 'dddd';
  let day = moment(date);
  if (timezone) {
    day = momentTimezone(day)
      .tz(timezone)
      .format(formatDay);
  }
  return day;
};

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertHoursToDays = numberOfHours => {
  const days = Math.floor(numberOfHours / 24);
  return days;
};

export const isToday = date => {
  const now = moment(moment().format('MM/DD/YYYY'));
  return now.diff(date, 'days') === 0;
};

export const isSameDay = (date1, date2) => {
  const year = moment().format('YYYY');
  const yearDate1 = moment(date1).format('YYYY');
  if (year !== yearDate1) {
    date1 = `${year}-${date1}`;
  }
  date1 = moment(moment(date1).format('MM/DD/YYYY'));
  date2 = moment(moment(date2).format('MM/DD/YYYY'));
  return date1.diff(date2, 'days') === 0;
};

export const getDayFormat = date => {
  const momentDate = moment(date);
  let dateFormatted = momentDate.locale(getCurrentLanguage()).format('LL');
  if (isToday(momentDate)) {
    dateFormatted = "Aujourd'hui";
  }
  return dateFormatted;
};

export const getDateFormat = date => {
  return moment(date)
    .locale(getCurrentLanguage())
    .format('LLL');
};
