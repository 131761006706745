import _ from 'lodash'
import React from 'react'
import {
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_DELIVERY,
  PRODUCT_UNITS
} from '../constants'
import store from '../redux/store'

import { isStringNotNull } from './dataType'
import { isObjectNotNull } from '../utils'

export const priceWithPercentage = (number, percentage) => {
  return parseFloat((parseFloat(number) + (number * percentage) / 100).toFixed(1));
};

export const getProductFees = () => {
  let productFees = null;
  const { configurationReducer, pendingOrderReducer } = store.getState();
  const { servicePrice } = configurationReducer;
  const { address: customerAddress = {}, orderType } = pendingOrderReducer;
  const { networkFees = {} } = servicePrice;
  const { delivery = {} } = networkFees;
  if (networkFees.productFees) {
    productFees = servicePrice.networkFees.productFees;
  }
  if (customerAddress && orderType === ORDER_TYPE_DELIVERY && delivery.productFees) {
    productFees = delivery.productFees;
  }
  return productFees;
};

export const getProductId = (productKey, productItems) => {
  let productId = `${productKey}`;
  if (!_.isEmpty(productItems)) {
    productId += '@';
    _.map(productItems, category => {
      if (category && category.options) {
        _.map(category.options, option => {
          productId += option.key;
        });
      }
    });
  }
  productId = productId
    .replace(/\s+/g, '_')
    .replace(/\//g, '_')
    .replace('.', '');
  return productId;
};

export const getProductUnits = unit => {
  if (isStringNotNull(unit)) {
    const units = [{ name: unit, conversion: 1 }];
    if (PRODUCT_UNITS[unit.toLowerCase()]) {
      _.map(PRODUCT_UNITS[unit.toLowerCase()], productUnit => {
        units.push(productUnit);
      });
    }
    return units;
  }
  return null;
};

export const getNbInCart = productId => {
  const { pendingOrderReducer } = store.getState();
  let nbInCart = 0;
  _.map(pendingOrderReducer?.commande, (productInCart, id) => {
    if (productId === id) {
      nbInCart = productInCart.nb;
    }
  });
  return nbInCart;
};

export const getNbInCartWithKey = productKey => {
  const { pendingOrderReducer } = store.getState();
  let nbInCart = 0;
  _.map(pendingOrderReducer?.commande, productInCart => {
    const { unit, key, nb } = productInCart;
    if (productKey === key) {
      if (isStringNotNull(unit)) {
        nbInCart += 1;
      } else {
        nbInCart += nb;
      }
    }
  });
  return nbInCart;
};

export const getFirstProductCategory = product => {
  const { categories } = product;
  let firstShopCategory = null;
  const shopCategories = store.getState().shopReducer?.categories;
  if (categories) {
    const productCategories = categories.split(',');
    const categoryKey = productCategories[0].split('@')[0];
    firstShopCategory = shopCategories[categoryKey];
    if (firstShopCategory) {
      firstShopCategory.path = categoryKey;
    }
  }
  return firstShopCategory;
};

export const getMinimumPriceFreeProduct = product => {
  let minimumPrice = null;
  const firstShopCategory = getFirstProductCategory(product);
  if (firstShopCategory) {
    _.map(firstShopCategory.items, item => {
      if (item) {
        const { price } = item;
        if (!minimumPrice || price < minimumPrice) {
          minimumPrice = price;
        }
      }
    });
  }
  return minimumPrice;
};

export const displayPriceFormat = (price, innerHtml, decimal = 2) => {
  price = `${String(Number(price).toFixed(decimal)).replace('.', ',')} €`;
  if (innerHtml) {
    return price;
  }
  return <span className='no-localization'>{price}</span>;
};

export const getOptionAssociatedProduct = option => {
  let product = null;
  const { sections } = store.getState().shopReducer;
  const { productKey } = option;
  if (productKey) {
    _.map(sections, section => {
      if (section) {
        const productSection = _.find(section.products, product => {
          return product.key === productKey;
        });
        if (productSection) {
          product = productSection;
        }
      }
    });
  }
  return product;
};

export const isProductDisabledOrderType = product => {
  const { address: customerAddress, orderType } = store.getState().pendingOrderReducer;
  let isProductDisabledOrderType = false;
  if (customerAddress) {
    isProductDisabledOrderType =
      (orderType === ORDER_TYPE_CLICK_AND_SEAT && product.disabledCS) ||
      (orderType === ORDER_TYPE_CLICK_AND_COLLECT && product.disabledCC) ||
      (orderType === ORDER_TYPE_DELIVERY && product.disabledDelivery);
  }
  return isProductDisabledOrderType;
};

export const isProductDisabled = product => {
  return isProductDisabledOrderType(product) || product.disabled || product.stock <= 0;
};

const isSectionDisabled = section => {
  const hasProductNotDisabled = _.find(section.products, product => {
    return !isProductDisabledOrderType(product) && !product.hidden;
  });
  return (section.products && !hasProductNotDisabled) || section.hidden;
};

export const filterSections = sections => {
  return sections?.length
  ? sections?.filter(section => section && !isSectionDisabled(section))
  : [];
};

export const getNbInCartOfProduct = (key, productKey) => {
  const { pendingOrderReducer } = store.getState();
  let nbInCart = 0;
  _.map(pendingOrderReducer?.commande, productInCart => {
    const { nb } = productInCart;
    if (key === productInCart.key || (productKey && productKey === productInCart.key)) {
      nbInCart += nb;
    } else {
      _.map(productInCart.items, itemInCart => {
        if (itemInCart) {
          _.map(itemInCart.options, optionInCart => {
            // productKey can be an option key or a product key
            if (key === optionInCart.key || key === optionInCart.productKey) {
              const optionProductNb = optionInCart.productNb || 1;
              const optionProduct = getOptionAssociatedProduct(optionInCart);
              if (optionProduct) {
                nbInCart += nb * optionProductNb;
              }
            }
          });
        }
      });
    }
  });
  return nbInCart;
};

export const getStockRemaining = (productStock, nbInCart, productNb) => {
  let stockRemainingOption = null;
  if (parseInt(productStock, 10) >= 0) {
    stockRemainingOption = parseFloat(productStock - nbInCart - productNb, 10);
  }
  return stockRemainingOption;
};

export const getCurrentNbOption = (productItems, optionKey) => {
  let nbOption = 0;
  _.map(productItems, category => {
    if (category && category.options) {
      _.map(category.options, opt => {
        if (opt.key === optionKey) {
          nbOption++;
        }
      });
    }
  });
  return nbOption;
};

export const getCounterOptionKey = (categoryKey, optionKey) => {
  return `${categoryKey}${optionKey}`;
};

export const getManagementFee = (totalProductPrice) => {
  const { managementFeeObj } = store.getState().configurationReducer;

  if (!isObjectNotNull(managementFeeObj)) {
    return null;
  }
  const { fee, maxFee, minFee } = managementFeeObj;
  return Math.min(Math.max((fee / 100) * totalProductPrice, minFee), maxFee);
};
