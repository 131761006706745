import React from 'react'
import { connect } from 'react-redux'
import 'moment/min/locales'
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import _ from 'lodash'
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent'
import Home from './Pages/Home'
import Landing from './Pages/Landing'
import Marketplace from './Pages/Marketplace/Marketplace.view'
import MessageModal from './components/MessageModal'
import CartModal from './components/CartModal'
import PaymentModal from './components/PaymentModal'
import InactivityModal from './components/InactivityModal'
import OrderModal from './components/OrderModal'
import Navbar from './components/Navbar'
import HorairesModal from './components/HorairesModal'
import ScrollToTop from './components/ScrollToTop'
import { updateShop } from './redux/actions/shopActions'
import { fetchConfiguration } from './redux/actions/configurationAction'
import { listenerAuth, userConnectionCheck } from './redux/actions/userAction'
import { initializeMessenger } from './api/messenger'
import { initializeFirebase } from './api/firebase/firebaseInit'
import { getShopsData } from './utils/shop'
import { BLACK, COLOR_LIGHT_GRAY, ORDER_TYPE_CLICK_AND_COLLECT, UNIT, WHITE } from './constants'
import { checkAccessContent, getMetadata, interceptConsole, isQrCode, isSplash, isTerminal, isWebView } from './utils'
import { updateAddressAndShop } from './utils/customer-address-and-shops'

import ConnexionModal from './components/ConnexionModal'
import AccountModal from './components/AccountModal'
import AddressModal from './components/AddressModal'
import './css/App.css'
import './css/react-phone-input.css'
import './css/App-dark.css'
import store from './redux/store'

const INITIAL_STATE = {
  stripe: false,
  displayAddressModal: false
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  async UNSAFE_componentWillMount() {
    const { fetchConfiguration, userConnectionCheck, updateShop, shopId } = this.props;
    interceptConsole();
    await initializeFirebase();
    await fetchConfiguration();
    await getShopsData();
    checkAccessContent();
    await userConnectionCheck();
    if (!shopId && !store.getState()?.configurationReducer?.marketplace?.enabled) { // Lorsqu'on recupere depuis les props il n'a pas encore la valeur definie
      await updateShop(null);
    }
    if (isWebView()) {
      initializeMessenger();
    }
  }

  componentWillUnmount() {
    const { listenerAuth } = this.props;
    if (!isTerminal())
      listenerAuth();
  }

  async UNSAFE_componentWillReceiveProps(nextProps) {
    const { customerAddress, updateShop, shops, shopId, orderType, marketplace } = this.props;
    if (nextProps.stripeApiKey) {
      this.setState({ stripe: loadStripe(nextProps.stripeApiKey) });
    }
    const customerAddressChanged =
      nextProps.customerAddress &&
      (!customerAddress ||
        !_.isEqual(
          _.omit(customerAddress, ['instruction', 'street2']),
          _.omit(nextProps.customerAddress, ['instruction', 'street2'])
        ));
    const isSelectedShopChanged = (shopId !== nextProps.shopId && nextProps.shopId?.length) ||
      (orderType !== nextProps.orderType && nextProps.orderType?.length);
    if ((customerAddressChanged || isSelectedShopChanged) && !marketplace?.enabled) {
      if (!marketplace?.enabled){
        updateShop(nextProps.customerAddress);
      }
    }
    if (!_.isEqual(shops, nextProps.shops) && !_.isEmpty(nextProps.shops)) {
      const shopKeys = Object.keys(nextProps.shops);
      if (shopKeys.length === 1) {
        const shopId = shopKeys[0];
        const shop = nextProps.shops[shopId];
        const { address, configuration } = shop;
        const { orderTypes } = configuration;
        if (
          orderTypes.includes(ORDER_TYPE_CLICK_AND_COLLECT) &&
          orderTypes.length === 1 &&
          !isQrCode() &&
          !isTerminal()
        ) {
          await updateAddressAndShop(shopId, address, ORDER_TYPE_CLICK_AND_COLLECT);
        }
      }
    }
  }

  render() {
    const {
      theme,
      webapp,
      name,
      logoUrl,
      landing,
      marketplace,
      secondaryColor,
      descriptionText
    } = this.props;
    const { stripe, displayAddressModal } = this.state;
    const { title, description, keywords } = getMetadata(name, webapp, descriptionText);
    const marketplaceEnabled = marketplace?.enabled;
    const landingEnabled = landing?.enabled;
    const navbarEnabled = webapp?.navbar?.enabled;
    const singlePageEnabled = isQrCode() || isTerminal() || isWebView();
    return (
      <div className={`${theme}-theme`}>
        {name && (
          <Helmet>
            <title>{title}</title>
            <meta name='description' content={description} />
            <link rel='shortcut icon' href={logoUrl} type='image/x-icon' />
            <meta name='keywords' content={keywords} />
          </Helmet>
        )}
        {((!marketplaceEnabled && !landingEnabled) || singlePageEnabled) && (
          <>
            {navbarEnabled && !singlePageEnabled && <Navbar />}
            <Home />
          </>
        )}
        {marketplaceEnabled && !singlePageEnabled && (
          <>
            <ScrollToTop />
            <Navbar />
            <Switch>
              <Route path='/:shopId' exact component={Home} />
              <Route path='/' component={Marketplace} />
            </Switch>
          </>
        )}
        {!marketplaceEnabled && !singlePageEnabled && landingEnabled && (
          <>
            {/* <ScrollToTop /> */}
            <Navbar />
            <Switch>
              <Route path='/commander/' exact component={Home} />
              <Route path='/' component={Landing} />
            </Switch>
          </>
        )}
        <MessageModal />
        {stripe && (
          <>
            <Elements stripe={stripe}>
              <ElementsConsumer>
                {({ elements, stripe }) => <CartModal elements={elements} stripe={stripe} />}
              </ElementsConsumer>
            </Elements>

            <Elements stripe={stripe}>
              <ElementsConsumer>
                {({ elements, stripe }) => <PaymentModal elements={elements} stripe={stripe} />}
              </ElementsConsumer>
            </Elements>
          </>
        )}
        <HorairesModal />
        <ConnexionModal />
        <AccountModal />
        {(getCookieConsentValue() === 'true' || displayAddressModal) && !marketplaceEnabled ? <AddressModal /> : null}
        <InactivityModal />
        <OrderModal />
        {!isSplash() && !isTerminal() && (
          <CookieConsent
            location='bottom'
            expires={365}
            overlay
            buttonText='Tout Accepter'
            buttonStyle={{
              background: secondaryColor || BLACK,
              color: WHITE,
              borderRadius: UNIT / 2
            }}
            style={{
              alignItems: 'center',
              backgroundColor: COLOR_LIGHT_GRAY,
              justifyContent: 'center'
            }}
            onAccept={() => {
              this.setState({ displayAddressModal: true });
            }}
            overlayStyle={{
              zIndex: 3000
            }}
          >
            <p className='text-secondary text-justify'>
              Nous utilisons des cookies pour offrir des fonctionnalités utiles et mesurer les
              performances afin d&apos;améliorer votre expérience.
            </p>
          </CookieConsent>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ shopReducer, configurationReducer, componentReducer, pendingOrderReducer }) => {
  const { shops } = shopReducer;
  const { splash } = componentReducer;
  const { address: customerAddress, orderType, shopId } = pendingOrderReducer;
  const {
    webapp,
    name,
    logoUrl,
    theme,
    stripeApiKey,
    landing,
    marketplace,
    secondaryColor,
    descriptionText
  } = configurationReducer;
  return {
    descriptionText,
    shopId,
    webapp,
    theme,
    logoUrl,
    name,
    stripeApiKey,
    landing,
    marketplace,
    customerAddress,
    shops,
    secondaryColor,
    splash,
    orderType
  };
};

export default connect(mapStateToProps, {
  fetchConfiguration,
  userConnectionCheck,
  listenerAuth,
  updateShop
})(App);
