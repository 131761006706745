import { sendPostRequest } from '../../api/backend';
import { getIdToken } from '../../api/firebase/firebaseAuthentication';
import { sendCloudWatchAlert } from '../../utils';
import { showMessageModal, showOrderStatus } from '../../redux/actions';
import store from '../../redux/store';
import {
  ORDER_PHASE_CONTACTING,
  ORDER_PHASE_PREPARING,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_TAKEAWAY,
  CUSTOMER_CANCEL_PHASE_CONTACTING,
  CUSTOMER_CANCEL_PHASE_PREPARING,
  CUSTOMER_CANCEL_PHASE_DELIVERING,
  CUSTOMER_CANCEL_PHASE_TAKEAWAY,
  CUSTOMER_CANCEL,
  API_CANCEL_ORDER
} from '../../constants';

export const cancelOrder = async (component, order) => {
  try {
    const { userConnected, user } = store.getState().userReducer;
    const { customerId } = user;
    const { status, chargeId, canal, orderNumber } = order;
    let cancelReason = '';
    switch (status) {
      case ORDER_PHASE_CONTACTING:
        cancelReason = CUSTOMER_CANCEL_PHASE_CONTACTING;
        break;
      case ORDER_PHASE_PREPARING:
        cancelReason = CUSTOMER_CANCEL_PHASE_PREPARING;
        break;
      case ORDER_PHASE_DELIVERING:
        cancelReason = CUSTOMER_CANCEL_PHASE_DELIVERING;
        break;
      case ORDER_PHASE_TAKEAWAY:
        cancelReason = CUSTOMER_CANCEL_PHASE_TAKEAWAY;
        break;
      default:
        break;
    }
    const cancellation = {
      canceledBy: CUSTOMER_CANCEL,
      cancelReason
    };
    const data = {
      customerId,
      cancellation,
      chargeId,
      canal
    };
    let headers = {};
    if (userConnected) {
      const idToken = await getIdToken();
      headers = { Authorization: `Bearer ${idToken}` };
    }
    await sendPostRequest(API_CANCEL_ORDER, data, headers);
    store.dispatch(showMessageModal(`Votre commande n°${orderNumber} a bien été annulée`));
    store.dispatch(showOrderStatus(false));
  } catch (error) {
    store.dispatch(
      showMessageModal(`Nous n'avons pas pu annuler votre commande n°${order.orderNumber}`)
    );
    sendCloudWatchAlert(`Error cancelling order ${error}`);
  }
};
