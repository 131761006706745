import _ from 'lodash'
import apiService from '@dishopsaas/dishop-backend-api-service'
import store from '../../redux/store'
import {
  MESSAGE_MODAL,
  SHOW_ORDER_MODAL,
  SHOW_ORDER_STATUS,
  UPDATE_CANCELED_ORDERS,
  UPDATE_DRIVERS_DATA,
  UPDATE_LAST_ORDER_CHARGE_IDS,
  UPDATE_ORDERS,
  UPDATE_PAST_ORDERS,
  UPDATE_PENDING_ORDER,
  UPDATE_SHOP,
  UPDATE_SHOP_IS_LOADED,
  UPDATE_SHOP_ORDERS
} from '../../redux/actions/types'
import {
  CANCEL_CLIENT_UNAVAILABLE,
  CUSTOMER_CANCEL,
  DRIVER_CANCEL,
  ORDER_PHASE_DELIVERING,
  ORDER_PHASE_PREPARING,
  PAYMENT_TYPE_STRIPE,
  SHOP_CANCEL_AND_REFUND
} from '../../constants'
import { updateAddress } from '../../redux/actions'
import { checkIfCustomerAddressValid, getCustomerAddress } from '../../utils/customer-address-and-shops'
import { getFirebaseFleet, getFirebaseModule } from './firebaseInit'
import { parseCategories, parseSections, setPendingOrderCookies } from '../../utils/index.js'

export const unlistenFirebase = customerId => {
  if (!customerId) {
    return;
  }
  getFirebaseModule()
    .database()
    .ref(`/users/${customerId}`)
    .off('value');
  getFirebaseModule()
    .database()
    .ref(`/orders/${customerId}`)
    .off('value');
  getFirebaseModule()
    .database()
    .ref(`/pendingOrders/${customerId}`)
    .off('value');
  getFirebaseModule()
    .database()
    .ref(`/pastOrders/${customerId}`)
    .off('value');
  getFirebaseModule()
    .database()
    .ref(`/canceledOrder/${customerId}`)
    .off('value');
};

export const listenFirebasePendingOrder = async customerId => {
  getFirebaseModule()
    .database()
    .ref(`/pendingOrders/${customerId}`)
    .on('value', snapshot => {
      const pendingOrder = snapshot.val();
      store.dispatch({
        type: UPDATE_PENDING_ORDER,
        payload: { ...pendingOrder, commande: pendingOrder?.commande || {} }
      });
      setPendingOrderCookies(!!pendingOrder?.address, pendingOrder?.address, pendingOrder?.shopId, pendingOrder?.orderType);
      checkIfCustomerAddressValid(pendingOrder?.address, pendingOrder?.orderType, pendingOrder?.shopId);
    });
}

export const listenOrders = shopId => {
  getFirebaseModule()
    .database()
    .ref('/orders')
    .off('value');
  getFirebaseModule()
    .database()
    .ref(`/orders`)
    .on('value', snapshot => {
      const ordersByCustomerId = snapshot.val();
      const ordersByShopId = [];
      _.map(ordersByCustomerId, orders => {
        _.map(orders, order => {
          if (order.shopId === shopId) {
            ordersByShopId.push(order);
          }
        });
      });
      store.dispatch({ type: UPDATE_SHOP_ORDERS, payload: ordersByShopId });
    });
};

export const listenLastOrderChargeId = async customerId => {
  getFirebaseModule()
    .database()
    .ref(`/users/${customerId}/lastOrderChargeIds`)
    .off('value');
  getFirebaseModule()
    .database()
    .ref(`/users/${customerId}/lastOrderChargeIds`)
    .on('value', snapshot => {
      const lastOrderChargeIds = snapshot.val();
      store.dispatch({ type: UPDATE_LAST_ORDER_CHARGE_IDS, payload: lastOrderChargeIds || {} });
    });
};

const listendFirebaseOrderTables = async (customerId, updateUser, isUserAnonymous) => {
  getFirebaseModule()
    .database()
    .ref(`/users/${customerId}`)
    .on('value', async snapshot => {
      // TODO why this cannot be made locally, as we know exactly when the user changes his address
      const customerProfile = snapshot.val() || {}
      const address = await getCustomerAddress(customerId)
      if (address) {
        store.dispatch(updateAddress(address))
      }
      store.dispatch(updateUser(customerProfile, customerId, isUserAnonymous))
    })
  getFirebaseModule()
    .database()
    .ref(`/orders/${customerId}`)
    .on('value', snapshot => {
      const newOrders = snapshot.val()
      const { orders } = store.getState().orderReducer
      if (newOrders) {
        _.map(newOrders, newOrder => {
          const { status, idDep, chargeId, delivery = {} } = newOrder
          const order = _.find(orders, order => {
            return order.chargeId === chargeId
          })
          if (status === ORDER_PHASE_DELIVERING && idDep) {
            listenDriverData(idDep, chargeId, delivery.freelance)
          }
          if (
            order &&
            status === ORDER_PHASE_PREPARING &&
            order.status === ORDER_PHASE_DELIVERING
          ) {
            unlistenDriverData(order.idDep, order.chargeId, delivery.freelance)
          }
        })
      }
      store.dispatch({ type: UPDATE_ORDERS, payload: newOrders })
    })
  getFirebaseModule()
    .database()
    .ref(`/pastOrders/${customerId}`)
    .on('value', async snapshot => {
      const pastOrders = snapshot.val()
      if (!_.isEmpty(pastOrders)) {
        const { lastOrderChargeIds } = store.getState().userReducer
        const { orders } = store.getState().orderReducer
        const { orderStatus } = store.getState().componentReducer
        const lastPastOrder = _.find(lastOrderChargeIds, lastOrderChargeId => {
          return pastOrders[lastOrderChargeId]
        })
        if (lastPastOrder) {
          const { orderNumber, chargeId, idDep, delivery = {} } = pastOrders[lastPastOrder]
          if (idDep) {
            unlistenDriverData(idDep, chargeId, delivery.freelance)
          }
          if (orderStatus === chargeId) {
            store.dispatch({ type: SHOW_ORDER_STATUS, payload: false })
          }
          if (_.isEmpty(orders)) {
            store.dispatch({ type: SHOW_ORDER_MODAL, payload: false })
          }
          store.dispatch({
            type: MESSAGE_MODAL,
            payload: {
              message: `Votre commande n°${orderNumber} est terminée. Merci pour votre fidélité et à très
              bientôt !`
            }
          })

          await apiService.usersDelete([customerId, 'lastOrderChargeIds', chargeId])
        }
        store.dispatch({ type: UPDATE_PAST_ORDERS, payload: pastOrders })
      } else {
        store.dispatch({ type: UPDATE_PAST_ORDERS, payload: {} })
      }
    })
  getFirebaseModule()
    .database()
    .ref(`/canceledOrder/${customerId}`)
    .on('value', async snapshot => {
      const canceledOrders = snapshot.val()
      if (!_.isEmpty(canceledOrders)) {
        const { lastOrderChargeIds } = store.getState().userReducer
        const { support } = store.getState().shopReducer
        const { orders } = store.getState().orderReducer
        const { orderStatus } = store.getState().componentReducer
        const lastCanceledOrder = _.find(lastOrderChargeIds, lastOrderChargeId => {
          return canceledOrders[lastOrderChargeId]
        })
        if (lastCanceledOrder) {
          const {
            cancellation,
            paymentType,
            userCancelServicePrice,
            orderNumber,
            chargeId,
            idDep,
            totalPriceOrder,
            delivery = {}
          } = canceledOrders[lastCanceledOrder]
          const { canceledBy, cancelReason } = cancellation
          if (idDep) {
            await unlistenDriverData(idDep, chargeId, delivery.freelance)
          }
          let cancelMessage = `Le magasin n'a pas pu prendre votre commande n°${orderNumber}. Vous pouvez contacter le magasin ${support && support.number ? `au ${support.number}` : ''} pour plus d'informations.`
          if (cancelReason === CANCEL_CLIENT_UNAVAILABLE) {
            if (canceledBy === DRIVER_CANCEL) {
              cancelMessage = `Le livreur a annulé votre commande n°${orderNumber} car vous étiez injoignable.`
            } else {
              cancelMessage = `Le magasin a annulé votre commande n°${orderNumber} car vous étiez injoignable.`
            }
            if (paymentType === PAYMENT_TYPE_STRIPE && userCancelServicePrice > 0) {
              cancelMessage += ` Vous avez été facturé d'un montant de ${userCancelServicePrice} €.`
            }
          }
          if (cancelReason === SHOP_CANCEL_AND_REFUND) {
            cancelMessage = `Le magasin a remboursé votre commande n°${orderNumber}. Vous avez été remboursé d'un montant de ${totalPriceOrder} €.`
          }
          if (canceledBy !== CUSTOMER_CANCEL) {
            store.dispatch({
              type: MESSAGE_MODAL,
              payload: {
                message: cancelMessage
              }
            })
          }
          if (orderStatus === chargeId) {
            store.dispatch({ type: SHOW_ORDER_STATUS, payload: false })
          }
          if (_.isEmpty(orders)) {
            store.dispatch({ type: SHOW_ORDER_MODAL, payload: false })
          }
          await apiService.usersDelete([customerId, 'lastOrderChargeIds', chargeId])
        }

        store.dispatch({ type: UPDATE_CANCELED_ORDERS, payload: canceledOrders })
      }
    })
}

export const listenFirebaseUsersData = async (customerId, isUserAnonymous, updateUser) => {
  unlistenFirebase(customerId);
  if (!isUserAnonymous) {
    await listenLastOrderChargeId(customerId);
  }
  await listendFirebaseOrderTables(customerId, updateUser, isUserAnonymous)
  await listenFirebasePendingOrder(customerId);
  return true;
};

export const listenShopData = async (
  previousShopId,
  shopId,
  customerAddress,
  getShopAndZoneData,
  orderType
) => {
  if (previousShopId !== shopId) {
    store.dispatch({ type: UPDATE_SHOP_IS_LOADED, payload: false });
    getFirebaseModule()
      .database()
      .ref(`/shops/${previousShopId}`)
      .off();
    getFirebaseModule()
      .database()
      .ref(`/menus/${previousShopId}`)
      .off();
  }
  getFirebaseModule()
    .database()
    .ref(`/shops/${shopId}`)
    .on('value', snapshot => {
      const firebaseShop = snapshot.val();
      const shop = getShopAndZoneData(
        shopId,
        firebaseShop,
        customerAddress || firebaseShop?.address,
        orderType
      );
    if (shop) {
        getFirebaseModule()
          .database()
          .ref(`/menus/${shopId}`)
          .on('value', snapshot => {
            const shopMenus = snapshot.val();
            if (shopMenus) {
              shop.sections = parseSections(shopMenus?.sections);
              shop.categories = parseCategories(shopMenus?.categories);
              shop.shopId = shopId;
              store.dispatch({ type: UPDATE_SHOP, payload: { ...shop, shopIsLoaded: true } });
              const pendingOrder = store.getState()?.pendingOrderReducer;
              checkIfCustomerAddressValid(pendingOrder?.address, pendingOrder?.orderType, shopId);
            }
          });
      }
    });
  listenOrders(shopId);
  return true;
};

export const listenDriverData = async (driverId, chargeId, driverFreelance) => {
  let firebase = getFirebaseModule();
  if (driverFreelance) {
    firebase = getFirebaseFleet();
  }

  firebase
    .database()
    .ref(`/drivers/${driverId}`)
    .off();
  firebase
    .database()
    .ref(`/drivers/${driverId}`)
    .on('value', snapshot => {
      if (snapshot.val()) {
        const { firstName, lastName, lat, long, numero } = snapshot.val();
        const driverData = {
          driverFirstName: firstName,
          driverLastName: lastName,
          driverLocation: {
            latitude: lat,
            longitude: long
          },
          driverNumero: numero
        };
        store.dispatch({ type: UPDATE_DRIVERS_DATA, payload: { driverData, chargeId } });
      }
    });
};

export const unlistenDriverData = async (driverId, chargeId, driverFreelance) => {
  let firebase = getFirebaseModule();
  if (driverFreelance) {
    firebase = getFirebaseFleet();
  }
  firebase
    .database()
    .ref(`/drivers/${driverId}`)
    .off();
  const driverData = null;
  store.dispatch({ type: UPDATE_DRIVERS_DATA, payload: { driverData, chargeId } });
};
