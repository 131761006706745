import {
    BLACK,
    ORDER_TYPE_CLICK_AND_COLLECT,
    ORDER_TYPE_CLICK_AND_COLLECT_LABEL,
    ORDER_TYPE_CLICK_AND_SEAT_LABEL,
    ORDER_TYPE_DELIVERY,
    ORDER_TYPE_DELIVERY_LABEL,
    PRIMARY,
    UNIT,
    WHITE
} from '../../../constants';
import _ from 'lodash';
import Spinner from '../../Spinner';
import styles from '../AddressInput.styles';
import Button from '../../Button';
import React from 'react';
import StoreIcon from '@mui/icons-material/Store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NearMeIcon from '@mui/icons-material/NearMe';
import { useAddressInputModal } from './useAddressInputModal';
import CheckIcon from '@mui/icons-material/Check';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { getAddressFormatted, getOrderTypeLabel } from '../../../utils';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import PlacesAutocomplete from 'react-places-autocomplete';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

const searchOptions = {
    componentRestrictions: { country: ['fr', 'mc', 'gf'] }
};

type AutocompleteSuggestionType = {
    active: boolean;
    placeId: string;
    formattedSuggestion: { mainText: string; secondaryText: string; }
};

export default function AddressInputModal() {
    const {
        showShopsDropdownList,
        handleAddressTypeSelection,
        handleOrderTypeSelection,
        handleAddressSelection,
        setTypedAddress,
        handleAddressInputFormControlError,
        handleShopSelection,
        handleShopsDropdownListClick,
        errorMessage,
        isMobile,
        addressModal,
        closestShops,
        isCustomerAddress,
        customerAddress,
        secondaryColor,
        orderTypes,
        orderTypeChoiceIndex,
        typedAddress,
        handleValidationButtonClick,
        shopDropdowListText,
        isFilledAddressValid,
        openShopsDropdownList,
        shouldRenderAddressSelectionRadioButton,
        orderType,
        hasErrorAddressMessage,
        shouldRenderAddressSearchInput,
        getShopsIsLoading,
        animatePlaceholder,
        inputElementRef,
        chooseShop,
        isFirstTimeFetching,
        displayIsSavingSpinner
    } = useAddressInputModal();
    const toggleHover = (id: string) => {
        document.getElementById(id).style.backgroundColor = secondaryColor;
        document.getElementById(id).style.borderRadius = '0px';
    };
    const toggleLeave = (id: string) => {
        document.getElementById(id).style.backgroundColor = 'transparent';
    };

    const constructValidateButtonText = (isMobile: boolean, orderType: string) => `Valider ${isMobile ? `${getOrderTypeLabel(orderType)?.toLowerCase()}` : ''} `;

    const renderShopsDropdownList = () => {
        return showShopsDropdownList() && (
            <div>
                <Button
                    type={PRIMARY}
                    className={`btn dark-modal  ${!openShopsDropdownList && !hasErrorAddressMessage && errorMessage && !getShopsIsLoading ? 'border-red' : 'border-color'
                    } ${openShopsDropdownList ? 'borderMenu menuStyle' : 'borderStyle'}`}
                    style={{
                        border: !openShopsDropdownList && `1px solid ${BLACK}`,
                        borderRadius: openShopsDropdownList && '0px',
                        ...styles.menuStyle,
                        ...styles.row
                    }}
                    onClick={() => handleShopsDropdownListClick()}
                >
                    <div style={styles.row as any}>
                        <StoreIcon className=' label' style={styles.startIcon}/>
                        <p className='label'
                           style={{ ...styles.inputText, ...(shopDropdowListText?.includes('Recherche') ? { color: '#6c757d' } : {}) }}>
                            {shopDropdowListText}
                        </p>
                    </div>
                    <div>
                        {!openShopsDropdownList ? (
                            <ExpandMoreIcon className='label'/>
                        ) : (
                            <ExpandLessIcon className='label'/>
                        )}
                    </div>
                </Button>
                {(openShopsDropdownList !== null) && (<ul
                    className={`menu ${openShopsDropdownList ? 'animationOpen' : 'animationClose'}`}
                    style={{
                        marginBottom: 0,
                        overflowY: closestShops?.length <= 2 && 'hidden' as any
                    }}
                >
                    {_.keys(closestShops).map((key, i) => {
                        const shop = closestShops[key];
                        if (!shop) {
                            return
                        }
                        const { name, distance } = shop;
                        return (
                            <li
                                key={i}
                                className='menu-item'
                                onMouseEnter={() => toggleHover(name)}
                                onMouseLeave={() => toggleLeave(name)}
                            >
                                <Button
                                    id={name}
                                    type={PRIMARY}
                                    style={{
                                        ...styles.row,
                                        justifyContent: 'space-between',
                                        color: `${BLACK}!important`,
                                        backgroundColor: 'transparent'
                                    }}
                                    onClick={() => handleShopSelection(shop?.shopId)}
                                >
                                    <div style={{ ...styles.row as any }}>
                                        <StoreIcon className='label' style={styles.startIcon}/>
                                        <p
                                            className='label'
                                            style={{
                                                ...styles.truncText as any,
                                                ...styles.inputText as any
                                            }}
                                        >
                                            {name}
                                        </p>
                                    </div>
                                    {distance >= 0 && (
                                        <div className=' lightcolor' style={styles.row as any}>
                                            <NearMeIcon
                                                style={{
                                                    fontSize: 'medium'
                                                }}
                                                className='label'
                                            />
                                            <span style={{ fontSize: UNIT * 0.875 }} className='label'>
                          {distance.toFixed(2)} km
                        </span>
                                        </div>
                                    )}
                                </Button>
                            </li>
                        );
                    })}
                </ul>)}
            </div>
        );
    };

    const renderAddressSearchAutocompleteInput = () => {
        return (
            <PlacesAutocomplete
                value={typedAddress}
                onChange={(typedAddress: string) => setTypedAddress(typedAddress)}
                onSelect={(selectedAddress: any, placeId: string) => handleAddressSelection(selectedAddress, placeId)}
                onError={(status: any, clearSuggestions: any) => handleAddressInputFormControlError(status, clearSuggestions)}
                shouldFetchSuggestions={typedAddress?.length > 6}
                highlightFirstSuggestion
                searchOptions={searchOptions}
                debounce={400}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) =>
                    (<div style={{ ...styles.margin }}>
                            <div
                                className={`inputStyle ${hasErrorAddressMessage && !getShopsIsLoading && 'border-red'}`}
                                style={{ marginBottom: showShopsDropdownList() ? UNIT * 0.875 : 0 }}
                            >
                                <LocationOnOutlinedIcon className='fa-map-marker' style={{ ...styles.space }}/>
                                <input
                                    {...getInputProps({
                                        className: `form-control no-localization dark-modal`,
                                        ref: inputElementRef,
                                        placeholder: animatePlaceholder,
                                        style: styles.addressInput,
                                        'aria-describedby': 'button-addon3',
                                        type: 'text'
                                    })}
                                />
                                {isFilledAddressValid && <CheckIcon style={styles.space} className='fa-check'/>}
                            </div>
                            <div
                                className='autocomplete-dropdown-container'
                                style={{ position: 'absolute', zIndex: 5000 }}
                            >
                                {loading && <Spinner/>}
                                {suggestions?.map((suggestion: AutocompleteSuggestionType) => {
                                    const className = suggestion?.active
                                        ? 'suggestion-item--active p-2'
                                        : 'suggestion-item p-2';
                                    const style = suggestion?.active
                                        ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                            color: BLACK
                                        }
                                        : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                            color: BLACK
                                        };
                                    return (
                                        <div
                                            key={suggestion.placeId}
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style
                                            })}
                                        >
                                            <strong className='no-localization'>
                                                {suggestion?.formattedSuggestion?.mainText}
                                            </strong>{' '}
                                            <small className='no-localization'>
                                                {suggestion?.formattedSuggestion?.secondaryText}
                                            </small>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
            </PlacesAutocomplete>
        );
    };

    const renderOrderTypeSwitch = () => {
        const listOrderTypes = [];
        _.map(orderTypes, c => {
            if (c === ORDER_TYPE_DELIVERY) {
                listOrderTypes.push({
                    label: (<>{ORDER_TYPE_DELIVERY_LABEL} {_.size(orderTypes) === 1 && (
                        <CheckIcon style={{ ...styles.space }} className='text-end'/>
                    )}{' '}</>),
                    icon: (
                        <>
                            <DirectionsBikeIcon
                                style={{ ...styles.space }}
                                className={`${_.size(orderTypes) === 1 && 'float-left'}`}
                            />

                        </>
                    )
                });
            } else if (c === ORDER_TYPE_CLICK_AND_COLLECT) {
                listOrderTypes.push({
                    label: (<>{ORDER_TYPE_CLICK_AND_COLLECT_LABEL} {_.size(orderTypes) === 1 && (
                        <CheckIcon style={{ ...styles.space }} className='text-end'/>
                    )}</>),
                    icon: (
                        <>
                            <LocalMallOutlinedIcon
                                style={{ ...styles.space }}
                                className={`${_.size(orderTypes) === 1 && 'float-left'}`}
                            />{' '}

                        </>
                    )
                });
            } else {
                listOrderTypes.push({
                    label: (<>{ORDER_TYPE_CLICK_AND_SEAT_LABEL} {_.size(orderTypes) === 1 && (
                        <CheckIcon style={{ ...styles.space }} className='text-end'/>
                    )}</>),
                    icon: (
                        <>
                            <RestaurantOutlinedIcon
                                style={{ ...styles.space }}
                                className={`${_.size(orderTypes) === 1 && 'float-left'}`}
                            />{' '}

                        </>
                    )
                });
            }
        });
        return (
            <BottomNavigation
                showLabels
                value={orderTypeChoiceIndex}
                onChange={async (event, selectedOrderTypeIndex) => await handleOrderTypeSelection(selectedOrderTypeIndex)}
                className='bottom-navigation-style dark-modal dark-color'
                style={{ marginBottom: UNIT, height: 40 }}
            >
                {listOrderTypes &&
                    _.map(listOrderTypes, (c, index) => {
                        return (
                            <BottomNavigationAction
                                key={index}
                                label={(!isMobile || (isMobile && _.size(listOrderTypes)) < 3) && c.label}
                                icon={c.icon}
                                value={index}
                                className='label'
                                style={{
                                    flexDirection: 'row',
                                    maxWidth: '100%',
                                    borderRadius: UNIT * 2,
                                    backgroundColor:
                                        orderTypeChoiceIndex === index && _.size(orderTypes) > 1 ? secondaryColor : 'transparent',
                                    color: orderTypeChoiceIndex === index && _.size(orderTypes) > 1 ? WHITE : BLACK
                                }}
                            />
                        );
                    })}
            </BottomNavigation>
        );
    };

    const renderAddressSelectionRadioButtons = () => {
        return (
            <RadioGroup
                aria-label='isCutomerAddress'
                name='isCutomerAddress'
                id='isCutomerAddress'
                value={isCustomerAddress}
                onChange={e => handleAddressTypeSelection(e)}
            >
                <div className='dishop-iso dark-modal'>
                    <div className=' align-items-center'>
                        <FormControlLabel
                            style={{
                                ...styles.formControlLabel
                            }}
                            control={
                                <div>
                                    <Radio disabled={getShopsIsLoading} value={true} style={{ color: secondaryColor }}/>
                                    Adresse enregistrée
                                </div>
                            }
                            label={<p style={styles.labelStyle}>{getAddressFormatted(customerAddress)}</p>}
                        />
                    </div>
                    <hr className='hr-gray mb-0' style={{ marginTop: UNIT * 0.75 }}/>

                    <div className='align-items-center'>
                        <FormControlLabel
                            style={{
                                ...styles.formControlLabel
                            }}
                            control={
                                <div>
                                    <Radio disabled={getShopsIsLoading} value={false} style={{ color: secondaryColor }}/>
                                    Ajouter une nouvelle adresse
                                </div>
                            }
                            label={<p style={styles.labelStyle}>Saisir une nouvelle adresse de livraison</p>}
                        />
                    </div>
                </div>
            </RadioGroup>
        );
    };

    return (
        <div className={`${!addressModal ? 'px-0 dishop-iso dark-modal' : 'px-0'}`}>
            <div className={'dishop-iso dark-modal'}>
                <p style={{ marginTop: 0 }} className='text-center  title2 label'>
                    Passer une commande
                </p>
                <div className='mt-3'>
                    {renderOrderTypeSwitch()}
                    {(isFirstTimeFetching && !closestShops?.length)
                        ? (<div className='row justify-content-center'>
                            <Spinner/>
                        </div>)
                        : <>
                            {shouldRenderAddressSelectionRadioButton && renderAddressSelectionRadioButtons()}
                            <div className='mt-3'>
                                {shouldRenderAddressSearchInput && renderAddressSearchAutocompleteInput()}
                                {renderShopsDropdownList()}
                            </div>
                            {(errorMessage && !getShopsIsLoading) &&
                              <p style={styles.errorText}>{errorMessage}</p>}
                            <>
                                <Button
                                    disabled={errorMessage || (getShopsIsLoading && chooseShop) || displayIsSavingSpinner}
                                    type={PRIMARY}
                                    className='btn position-relative'
                                    style={styles.btn}
                                    onClick={() => handleValidationButtonClick()}
                                >
                                    {getShopsIsLoading && !chooseShop
                                        ? (<Spinner color={WHITE} size='sm'/>)
                                        : constructValidateButtonText(isMobile, orderType)}
                                    {displayIsSavingSpinner && (
                                        <span className='position-absolute' style={{ right: UNIT }}>
                                            <Spinner color={WHITE} size='sm'/>
                                        </span>)}
                                </Button>
                            </>
                        </>}
                </div>
            </div>
        </div>
    )
}