import { BLACK, WHITE } from '../../../constants'

const styles = {
  overlayCover: {
    minWidth: '100%',
    height: '100%'
  },
  container: {
    backgroundColor: WHITE,
    textAlign: 'left'
  }
}

export const backgroundHeightProperty = (fullScreenBackgroundImg, isMobile) => fullScreenBackgroundImg || isMobile
  ? { height: fullScreenBackgroundImg && window.innerHeight * 0.915 || isMobile && '36vw' }
  : null

export const mobileDescriptionStyle = (isMobile) => {
  const mobileStyle = isMobile
    ? {
      display: '-webkit-box',
      WebkitLineClamp: 2,
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'normal'
    }
    : null
  return {
    textShadow: `2px 2px 4px ${BLACK}`,
    ...mobileStyle
  }
}

export default styles