import { sendPostRequest } from '.';
import { API_CREATE_CUSTOMER_HUBRISE, API_UPDATE_CUSTOMER_HUBRISE } from '../constants';
import store from '../redux/store';
import { getIdToken } from './firebase/firebaseAuthentication'

export const signUpCustomerHubrise = async (customerId, customerData) => {
  const { shopConfiguration, shopId } = store.getState().shopReducer;
  const { hubrise } = shopConfiguration;
  if (hubrise && hubrise.enabled) {
    const idToken = await getIdToken();
    const { firstName, lastName, email, newsletter, numero } = customerData;
    const customer = {
      first_name: firstName,
      last_name: lastName,
      email,
      sms_marketing: !!newsletter,
      email_marketing: !!newsletter
    };
    if (numero) {
      customer.phone = numero;
    }
    const response = await sendPostRequest(
      API_CREATE_CUSTOMER_HUBRISE,
      { customer, shopId, customerId },
      { Authorization: `Bearer ${idToken}` }
    );
    const hubriseId = response.data;
    return hubriseId;
  }
  return false;
};

export const updateCustomerHubrise = async (customerObject) => {
  const { shopConfiguration, shopId } = store.getState().shopReducer;
  const { hubrise } = shopConfiguration;
  if (hubrise && hubrise.enabled) {
    const idToken = await getIdToken();
    const { user } = store.getState().userReducer;
    const { hubriseId, email } = user;
    let customer = {};
    if (customerObject && hubriseId) {
      const { address, numero } = customerObject;
      if (address) {
        const {
          city,
          street,
          street2 = '',
          location,
          postalCode,
          instruction = '',
          streetNumber
        } = address;
        customer = {
          address_1: `${streetNumber ? `${streetNumber} ` : ''}${street}`,
          address_2: street2,
          city,
          postal_code: String(postalCode),
          latitude: location.latitude.toString(),
          longitude: location.longitude.toString(),
          delivery_notes: instruction,
          phone: numero
        };
      } else {
        const { firstName, lastName, numero } = customerObject;
        customer = {
          first_name: firstName,
          last_name: lastName,
          phone: numero
        };
      }
      customer.email = email;
      const response = await sendPostRequest(
        API_UPDATE_CUSTOMER_HUBRISE,
        { customer, hubriseId, shopId },
        { Authorization: `Bearer ${idToken}` }
      );
      return response;
    }
  }
  return false;
};
