import {
    getAddressDetails,
    getAddressFormatted,
    getClosestShops,
    isAddressValid,
    isQrCode,
    parseAddress,
    renderErrorMessage,
    sendCloudWatchAlert
} from '../../../utils';
import {
    DEFAULT_TEXT,
    NO_SHOP_AVAILABLE_ERROR_MESSAGE,
    NO_SHOP_SELECTED_ERROR_MESSAGE,
    NOT_FOUND_INDEX,
    ORDER_TYPE_DELIVERY,
    PLACEHOLDER_TEXT,
    UNAVAILABLE_ADDRESS,
    WRONG_ADDRESS
} from '../../../constants';
import { useAppDispatch, useAppSelector } from '../../../redux/hook';
import { useCallback, useEffect, useRef, useState } from 'react';
import { geocodeByAddress, geocodeByPlaceId, getLatLng } from 'react-places-autocomplete/dist';
import Geocode from 'react-geocode/lib';
import { showAddressModal, updateClosestShops, updateCustomerAddressCheck } from '../../../redux/actions';
import { getDeliverableShops, updateAddressAndShop } from '../../../utils/customer-address-and-shops';
import { CUSTOMER_ADDRESS_CHECK_VALUES } from '../../../redux/reducers/componentReducer';

const DEBOUNCE_TIME = 200;
const getShops = async (address: any, orderType: string) => (await getClosestShops(address, orderType))
    ?.filter(shop => !shop?.closed);

export const useAddressInputModal = () => {
    const dispatch = useAppDispatch();
    const {
        secondaryColor,
        orderTypes: defaultShopOrderTypes,
        delivery,
        qrcode
    } = useAppSelector(state => state.configurationReducer);
    const {
        closestShops: defaultClosestChops = [],
    } = useAppSelector(state => state.shopReducer as any);
    const {
        orderType: pendingOrderOrderType,
        address: customerAddress,
        shopId: pendingOrderShopId
    } = useAppSelector(state => state.pendingOrderReducer);
    const orderTypes = isQrCode() ? qrcode?.orderTypes : defaultShopOrderTypes
    const closestShops = isQrCode() ? [defaultClosestChops?.find((shop: any) => shop?.shopId === pendingOrderShopId)] : defaultClosestChops;
    const { isMobile, googleMapsEnabled, addressModal } = useAppSelector(state => state.componentReducer);
    const [isCustomerAddress, setIsCustomerAddress] = useState(!!customerAddress)
    const [typedAddress, setTypedAddress] = useState('');
    const [lastTypedAddressValidValue, setLastTypedAddressValidValue] = useState('');
    const [selectedShopId, setSelectedShopId] = useState(pendingOrderShopId || null);
    const [selectedAddressPlaceId, setSelectedAddressPlaceId] = useState(null)
    const [getShopsIsLoading, setGetShopsIsLoading] = useState(false)
    const [openShopsDropdownList, setOpenShopsDropdownList] = useState(null)
    const defaultOrderTypeIndex = orderTypes?.findIndex((type: string) => (type === pendingOrderOrderType));
    const [orderTypeChoiceIndex, setOrderTypeChoiceIndex] = useState(defaultOrderTypeIndex !== NOT_FOUND_INDEX ? defaultOrderTypeIndex : 0)
    const [errorMessage, setErrorMessage] = useState(null)
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [shopDropdowListText, setShopDropdowListText] = useState(DEFAULT_TEXT);
    const [isFirstTimeFetching, setIsFirstTimeFetching] = useState(true);
    const [displayIsSavingSpinner, setDisplayIsSavingSpinner] = useState(false);
    const isMountedRef = useRef(true);
    const inputElementRef = useRef(null);

    const orderType = orderTypes[orderTypeChoiceIndex];
    const selectedShop = selectedShopId && closestShops?.find((shop: any) => shop?.shopId === selectedShopId);
    const hasErrorAddressMessage = [UNAVAILABLE_ADDRESS, WRONG_ADDRESS].includes(errorMessage);
    const isFilledAddressValid = isCustomerAddress
        ? !!customerAddress
        : !!selectedAddressPlaceId && typedAddress === lastTypedAddressValidValue && lastTypedAddressValidValue?.length && !hasErrorAddressMessage;
    const showShopsDropdownListWhenOrderTypeDelivery = (isCutomerAddress: boolean, hasErrorOnAddress: boolean, orderType: string) =>
        (delivery?.chooseShop && orderType === ORDER_TYPE_DELIVERY && (isCutomerAddress || (!isCutomerAddress && selectedAddressPlaceId)))
        && (!hasErrorOnAddress || getShopsIsLoading);
    const showShopsDropdownList = (isCutomerAddressSelected = isCustomerAddress, hasErrorOnAddress = hasErrorAddressMessage, orderTypeSelected = orderType) =>
        showShopsDropdownListWhenOrderTypeDelivery(isCutomerAddressSelected, hasErrorOnAddress, orderTypeSelected) || (orderTypeSelected !== ORDER_TYPE_DELIVERY && !!closestShops?.length);
    const shouldRenderAddressSelectionRadioButton =
        customerAddress &&
        (orderType === ORDER_TYPE_DELIVERY || (orderType !== ORDER_TYPE_DELIVERY && closestShops?.length > 1));
    const shouldRenderAddressSearchInput =
        ((orderType !== ORDER_TYPE_DELIVERY && closestShops?.length > 1) || orderType === ORDER_TYPE_DELIVERY) &&
        googleMapsEnabled &&
        !isCustomerAddress;
    const animatePlaceholder = orderTypes[orderTypeChoiceIndex] === ORDER_TYPE_DELIVERY
        ? PLACEHOLDER_TEXT
        : `${PLACEHOLDER_TEXT}(facultatif)`;
    const shoulRenderSpinner = isFirstTimeFetching;

    const getAddressFromCustomerAddress = useCallback(async (customerAddress: any) =>
        customerAddress?.placeId
            ? geocodeByPlaceId(customerAddress.placeId)
            : geocodeByAddress(getAddressFormatted(customerAddress)), [customerAddress]);

    const fetchAdressData = useCallback(async (selectedAddressPlaceId: string, customerAddress: any) => {
        try {
            const foundPlaces = await (selectedAddressPlaceId
                    ? geocodeByPlaceId(selectedAddressPlaceId)
                    : getAddressFromCustomerAddress(customerAddress)
            );
            const firstFoundPlace = foundPlaces[0];
            const foundSelectedAddress = foundPlaces?.length
                ? parseAddress(firstFoundPlace?.address_components)
                : null;
            if (!foundSelectedAddress) {
                setSelectedAddress(null);
                return null;
            }
            const { lat, lng } = await getLatLng(firstFoundPlace);
            const addresses = await Geocode.fromLatLng(lat, lng);
            const { district, subLocality } = getAddressDetails(addresses.results);
            foundSelectedAddress.district = district;
            foundSelectedAddress.subLocality = subLocality;
            foundSelectedAddress.location = {
                latitude: lat,
                longitude: lng
            };
            foundSelectedAddress.placeId = firstFoundPlace?.place_id;
            if (!isMountedRef.current) {
                return;
            }
            setSelectedAddress(foundSelectedAddress);
            return foundSelectedAddress;
        } catch (error) {
            await handleCatchError(error);
        }
    }, [orderType]);

    const getAvailableClosestShopsFromAddress = useCallback(async (orderType: string, selectedAddress: any) => {
        try {
            if (!isAddressValid(selectedAddress, orderType)) {
                return await getShopsIfInvalidAddress(orderType);
            }
            const shops = await getShops(selectedAddress, orderType);
            if (!isMountedRef.current) {
                return;
            }
            if (!shops?.length) {
                setErrorMessage(
                    orderType === ORDER_TYPE_DELIVERY
                    ? UNAVAILABLE_ADDRESS
                    : NO_SHOP_AVAILABLE_ERROR_MESSAGE
                );
                return [];
            }
            if (orderType !== ORDER_TYPE_DELIVERY) {
                handleOnlyOneShopAvailable(shops);
                dispatch(updateClosestShops(shops));
                setErrorMessage(null)
                return shops;
            }
            const deliverableShops = await getDeliverableShops(selectedAddress, shops, getShopsIsLoading, setGetShopsIsLoading, handleCatchError);
            if (!isMountedRef.current) {
                return;
            }
            if (!deliverableShops?.length) {
                setErrorMessage(UNAVAILABLE_ADDRESS)
                setGetShopsIsLoading(false);
                return [];
            }
            handleSelectedShopForDelivery(deliverableShops)
            dispatch(updateClosestShops(deliverableShops));
            setErrorMessage(null)
            setGetShopsIsLoading(false);
            return deliverableShops;
        } catch (error) {
            await handleCatchError(error);
            setGetShopsIsLoading(false);
        }
    }, [customerAddress, orderTypeChoiceIndex, selectedAddressPlaceId, isCustomerAddress, errorMessage]);

    const getShopsIfInvalidAddress = useCallback(async (orderType: string) => {
        if (orderType !== ORDER_TYPE_DELIVERY) {
            const shops = await getShops(null, orderType);
            dispatch(updateClosestShops(shops));
            setErrorMessage(shops?.length ? null : NO_SHOP_AVAILABLE_ERROR_MESSAGE)
            if (shops?.length === 1) {
                setSelectedShopId(shops?.length === 1 ? shops[0]?.shopId : null)
            }
            return shops;
        }
    }, [])

    const handleValidationButtonClick = useCallback(async () => {
        if (getShopsIsLoading || displayIsSavingSpinner) {
            return;
        }
        if (orderType === ORDER_TYPE_DELIVERY && !isCustomerAddress && !selectedAddressPlaceId) {
            setErrorMessage(WRONG_ADDRESS);
            return;
        }
        if (!selectedShop) {
            setErrorMessage(NO_SHOP_SELECTED_ERROR_MESSAGE);
            return;
        }
        if (selectedShopId === pendingOrderShopId && orderType === pendingOrderOrderType && isCustomerAddress && customerAddress?.placeId) {
            dispatch(showAddressModal(false));
            return;
        }
        setDisplayIsSavingSpinner(true)
        const customerNewAddress = selectedAddress || selectedShop?.address;
        dispatch(updateCustomerAddressCheck(CUSTOMER_ADDRESS_CHECK_VALUES.VALID));
        await updateAddressAndShop(
            selectedShopId,
            customerNewAddress,
            orderType
        );
        setDisplayIsSavingSpinner(false)
        dispatch(showAddressModal(false));
    }, [orderTypeChoiceIndex, selectedShopId, selectedAddressPlaceId, defaultClosestChops, getShopsIsLoading]);

    const handleAnimatePlaceHolderText = useCallback(() => {
        let str = '';
        let n = 0;
        const animate = () => {
            if (inputElementRef.current) {
                if (n < animatePlaceholder.length) {
                    str += animatePlaceholder[n];
                    inputElementRef.current.placeholder = str;
                    n += 1;
                } else {
                    str = '';
                    n = 0;
                    inputElementRef.current.placeholder = str;
                    setTimeout(animate, DEBOUNCE_TIME * 5);
                    return;
                }
            }
        }
        const interval = setInterval(animate, DEBOUNCE_TIME);
        return () => clearInterval(interval);
    }, [orderTypeChoiceIndex]);

    useEffect(() => {
        if (getShopsIsLoading) {
            let dots = '';
            const animateDots = () => {
                if (dots.length <= 3) {
                    setShopDropdowListText(`Recherche en cours${dots}`);
                    dots += '.';
                } else {
                    dots = '';
                }
            };
            const debounceTimer = setInterval(animateDots, DEBOUNCE_TIME);
            return () => clearTimeout(debounceTimer);
        } else {
            setShopDropdowListText(selectedShop?.name || DEFAULT_TEXT);
        }
    }, [getShopsIsLoading, selectedShop]);

    useEffect(() => {
        const fetchData = async () => {
            if (isCustomerAddress) {
                fetchAdressData(selectedAddressPlaceId, customerAddress)
                    .then((initialAddress: any) => getAvailableClosestShopsFromAddress(orderType, initialAddress))
                    .then((availableShops: any[]) => {
                        if (!isMountedRef.current) {
                            return;
                        }
                        setIsFirstTimeFetching(false);
                        const availableShopsHasPendingOrderShopId = !!availableShops?.find(shop => shop?.shopId === pendingOrderShopId);
                        if (!availableShopsHasPendingOrderShopId) {
                            setSelectedShopId(null)
                        }
                    })
            } else {
                const fetchShops = orderType !== ORDER_TYPE_DELIVERY
                    ? getAvailableClosestShopsFromAddress(orderType, null)
                    : Promise.resolve([]);
                fetchShops.then((availableShops: any[]) => {
                    if (availableShops?.length > 1) {
                        setSelectedShopId(null);
                    }
                    setIsFirstTimeFetching(false);
                })
            }
        };
        isMountedRef.current = true;
        fetchData();
        return () => {
            isMountedRef.current = false;
        };
    }, [customerAddress, pendingOrderShopId]);

    useEffect(() => {
        const fetchData = async () => {
            if (
                (!isCustomerAddress && !selectedAddressPlaceId) ||
                (!closestShops?.length && !lastTypedAddressValidValue?.length) ||
                (!isMountedRef.current && !!closestShops?.length) ||
                (orderType !== ORDER_TYPE_DELIVERY && isCustomerAddress) ||
                isFirstTimeFetching
            ) {
                return;
            }
            fetchAdressData(selectedAddressPlaceId, customerAddress)
                .then(async (foundAddress: any) => getAvailableClosestShopsFromAddress(orderType, foundAddress)
                    .then((availableClosestShops: any[]) => {
                        if (!isMountedRef.current) {
                            return;
                        }
                        const closestShopsHasPendingOrderShopId = !!availableClosestShops?.find(shop => shop?.shopId === pendingOrderShopId);
                        if (
                            isCustomerAddress &&
                            (orderType !== ORDER_TYPE_DELIVERY || orderType === ORDER_TYPE_DELIVERY && showShopsDropdownList(isCustomerAddress, hasErrorAddressMessage, orderType)) &&
                            closestShopsHasPendingOrderShopId
                        ) {
                            setSelectedShopId(pendingOrderShopId)
                        } else if (orderType === ORDER_TYPE_DELIVERY && !showShopsDropdownList(isCustomerAddress, hasErrorAddressMessage, orderType)) {
                            setSelectedShopId(availableClosestShops[0]?.shopId);
                        } else {
                            const shopId = availableClosestShops?.length === 1 ? availableClosestShops[0]?.shopId : null;
                            setSelectedShopId(shopId);
                        }
                    })
                )
        };
        isMountedRef.current = true;
        fetchData();
        return () => {
            isMountedRef.current = false;
        };
    }, [customerAddress, selectedAddressPlaceId, isCustomerAddress, orderTypeChoiceIndex]);

    useEffect(() => {
        return handleAnimatePlaceHolderText();
    }, [orderTypeChoiceIndex]);

    const handleCatchError = async (error: any) => {
        const errorMessage = String(error)
        if (errorMessage) {
            if (!errorMessage.includes('déployé sur') && !errorMessage.includes('incorrecte')) {
                sendCloudWatchAlert(`Could not add address: ${errorMessage}`)
            }
            if (errorMessage.includes('ZERO_RESULTS')) {
                setErrorMessage(UNAVAILABLE_ADDRESS)
            }
            if (errorMessage.includes('CANT_GEOCODE_ADDRESS')) {
                setErrorMessage(renderErrorMessage('stuart/address-not-served'));
            }
            if (!errorMessage?.includes('INVALID_REQUEST') && !errorMessage?.includes('ZERO_RESULTS')) {
                sendCloudWatchAlert(`Could not get address ${error}`)
            }
        } else {
            setErrorMessage(null)
        }
    }

    const handleOnlyOneShopAvailable = (shops: any[]): void => {
        if (shops?.length === 1) {
            setSelectedShopId(shops[0]?.shopId)
        }
    };

    const handleSelectedShopForDelivery = (shops: any[]): void => {
        if (shops?.length && !showShopsDropdownList() && !isCustomerAddress) {
            setSelectedShopId(shops[0]?.shopId)
        }
    };

    const handleShopSelection = (shopId: string) => {
        if (!shopId) {
            return;
        }
        setSelectedShopId(shopId);
        setOpenShopsDropdownList(false);
        setErrorMessage(null);
    };

    const handleAddressTypeSelection = async ({ target: target }) => {
        const isCustomerAddressChoosen = target.value === 'true';
        setIsCustomerAddress(isCustomerAddressChoosen);
        setErrorMessage(null)
        if (isCustomerAddressChoosen) {
            setSelectedAddressPlaceId(null)
            setTypedAddress('');
            const isOrderTypeDeliveryAndShopsDropdownListHidden = orderType === ORDER_TYPE_DELIVERY && !showShopsDropdownList(isCustomerAddressChoosen, false);
            setSelectedShopId((!isOrderTypeDeliveryAndShopsDropdownListHidden && pendingOrderShopId) || (closestShops?.length && closestShops[0]?.shopId) || null)
            if (orderType === ORDER_TYPE_DELIVERY) {
                setOpenShopsDropdownList(null);
            }
        } else {
            setSelectedShopId(null)
            if (orderType !== ORDER_TYPE_DELIVERY) {
                await getAvailableClosestShopsFromAddress(orderType, selectedAddress);
            } else {
                setOpenShopsDropdownList(null);
            }
        }
    };

    const handleOrderTypeSelection = async (selectedOrderTypeIndex: number) => {
        if (getShopsIsLoading) {
            return;
        }
        const selectedOrderType = orderTypes[selectedOrderTypeIndex];
        setOrderTypeChoiceIndex(selectedOrderTypeIndex);
        setOpenShopsDropdownList(null)
        setErrorMessage(null)
        if (
            (typedAddress !== lastTypedAddressValidValue && lastTypedAddressValidValue?.length) ||
            !lastTypedAddressValidValue?.length
        ) {
            setSelectedAddressPlaceId(null)
            setTypedAddress('')
            setLastTypedAddressValidValue('')
        }
        if (selectedOrderType === ORDER_TYPE_DELIVERY) {
            return;
        }
        const availableClosestShops = await getAvailableClosestShopsFromAddress(selectedOrderType, selectedAddress);
        const closestShopsHasPendingOrderShopId = !!availableClosestShops?.find(shop => shop?.shopId === pendingOrderShopId);
        if (isCustomerAddress && closestShopsHasPendingOrderShopId) {
            setSelectedShopId(pendingOrderShopId)
        } else {
            const shopId = availableClosestShops?.length === 1 ? availableClosestShops[0]?.shopId : null;
            setSelectedShopId(shopId);
        }
    }

    const handleAddressSelection = async (selectedAddressValue: any, placeId: string) => {
        if (placeId === selectedAddressPlaceId) {
            setTypedAddress(selectedAddressValue);
            return;
        }
        if (orderType === ORDER_TYPE_DELIVERY && placeId !== selectedAddressPlaceId) {
            setOpenShopsDropdownList(null);
        }
        setTypedAddress(selectedAddressValue);
        setLastTypedAddressValidValue(selectedAddressValue);
        setSelectedAddressPlaceId(placeId);
        setSelectedShopId(null);
    }

    const handleAddressInputFormControlError = (status: any, clearSuggestions: any) => {
        const errorMessage = String(status);
        if (!errorMessage.includes('ZERO_RESULTS')) {
            sendCloudWatchAlert(`Error from google api ${status}`);
        }
        setErrorMessage(WRONG_ADDRESS);
        clearSuggestions();
    }

    const handleShopsDropdownListClick = () => {
        if (!getShopsIsLoading) {
            setOpenShopsDropdownList(!openShopsDropdownList)
            if (!openShopsDropdownList) {
                setErrorMessage(null)
            }
        }
    }

    return {
        showShopsDropdownList,
        handleAddressTypeSelection,
        handleOrderTypeSelection,
        handleAddressSelection,
        setTypedAddress,
        handleAddressInputFormControlError,
        setOpenShopsDropdownList,
        handleShopsDropdownListClick,
        handleShopSelection,
        setErrorMessage,
        errorMessage,
        isMobile,
        addressModal,
        closestShops,
        isCustomerAddress,
        customerAddress,
        secondaryColor,
        orderTypes,
        orderTypeChoiceIndex,
        typedAddress,
        handleValidationButtonClick,
        selectedShop,
        isFilledAddressValid,
        openShopsDropdownList,
        shouldRenderAddressSelectionRadioButton,
        orderType,
        hasErrorAddressMessage,
        shouldRenderAddressSearchInput,
        getShopsIsLoading,
        animatePlaceholder,
        inputElementRef,
        shopDropdowListText,
        shoulRenderSpinner,
        chooseShop: delivery?.chooseShop,
        isFirstTimeFetching,
        displayIsSavingSpinner
    }
}