export const API_STREAM_LOGS = '/api/stream_logs';
// Payment APIs
export const API_RETRIEVE_CARD = 'api/retrieve_card';
export const API_CREATE_PAYMENT_INTENT = '/api/create_payment_intent';
export const API_PAYMENT_CONFIRM = '/api/payment/confirm';
export const API_SETUP_PAYMENT_INTENT = '/api/setup_payment_intent';
export const API_CREATE_CUSTOMER_STRIPE = '/api/create_customer';
export const API_ADD_CARD_CUSTOMER = '/api/add_card_customer';
export const API_UPDATE_DEFAULT_SOURCE_CUSTOMER = '/api/update_default_source_customer';
export const API_DELETE_CARD_CUSTOMER = '/api/delete_card_customer';
export const API_PAYMENT_CUSTOMER = '/api/payment_customer';
export const API_CUSTOMER_REFUND = '/api/customer_refund';
export const API_CAPTURE_PAYMENT_INTENT = '/api/capture_payment_intent';
export const API_SEND_NOTIFICATION = '/api/send_notification';
export const API_GET_DISTANCE = 'api/get_distance';
export const API_ADD_PROMOTION = '/api/add_promotion';
export const API_SEND_ORDER_MESSENGER = '/api/send_order_messenger';
export const API_CANCEL_ORDER = '/api/cancel_order';
export const API_SEND_ORDER = '/api/send_order';
export const API_PAYMENT_TRMINAL = '/api/payment/terminal';

// Delivery APIs
export const API_VALIDATE_ADDRESS_DELIVERY = '/api/validate_address_delivery';
export const API_REQUEST_DELIVERY_PRICING = '/api/delivery/pricing';

// Customer APis
export const API_CREATE_CUSTOMER = '/api/customer/create';
export const API_GET_CUSTOMER = '/api/customer';
export const API_UPDATE_PAYMENT_CARD_CUSTOMER = '/api/customer/update_card';
export const API_ADD_PAYMENT_CARD_CUSTOMER = '/api/customer/add_card';
export const API_DELETE_CUSTOMER_ACCOUNT = '/api/customer/delete';

// Hubrise APIs
export const API_CREATE_CUSTOMER_HUBRISE = '/api/hubrise/customer/create';
export const API_UPDATE_CUSTOMER_HUBRISE = '/api/hubrise/customer/update';
