import React from 'react'
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { Fade } from 'react-awesome-reveal'
import PhoneInput from '../PhoneInput'
import TextInputCounter from '../TextInputCounter'
import ModalBorder from '../ModalBorder'
import Button from '../Button'
import {
  CHOOSE_PAYMENT_TYPE_CONTENT,
  INPUT_EASEL_NUMBER_CONTENT,
  ORDER_TYPE_CLICK_AND_COLLECT,
  ORDER_TYPE_CLICK_AND_SEAT,
  ORDER_TYPE_DELIVERY,
  PAYMENT_TYPE_STRIPE,
  SECURE_PAYMENT_STRIPE_IMAGE_URL
} from '../../constants'
import Order from '../Order'
import Spinner from '../Spinner'
import AddPayment from '../AddPayment'
import {
  showCartModal,
  showConnexionModal,
  showMessageModal,
  showOverlayCover,
  showPaymentModal,
  updateAddressAttribute,
  updateOrderTime,
  updateProfile
} from '../../redux/actions'
import { addCustomerCard } from '../../api'
import {
  checkOrder,
  convertHoursToDays,
  getAddressFormatted,
  getCreditCard,
  getDayFormat,
  getPaymentTypeLabel,
  getPaymentTypes,
  isQrCode,
  isStringNotNull,
  isTerminal,
  isTerminalVertical,
  isWebView
} from '../../utils'
import { COLOR_LIGHT_GRAY, PRIMARY, SECONDARY, UNIT } from '../../constants/configuration'
import {
  getExcludedOrderDays,
  getFirstDay,
  getMaximumPreparationTime,
  getOrderTimes,
  sendOrderMessenger
} from './CartModal.services'
import store from '../../redux/store'
import styles from './CartModal.styles'

import fr from 'date-fns/locale/fr'

const INITIAL_STATE = {
  loading: false,
  choosePayment: false,
  paymentType: '',
  stripeSCA: {},
  hourSelectValue: 0,
  day: null,
  isValidDate: true,
  selectDay: false,
  selectTime: false,
  isPhoneIncorrect: false
};

function CustomDayPickerInput({ value, isValidDate, orderTime, closed, onClick }) {
  return (
    <Button
      className={`text-left w-100 ${ (isValidDate || orderTime) ? 'datepicker-input' : 'border-red'}  label`}
      style={{ borderRadius: UNIT / 4 }}
      onClick={onClick}
    >
     {!closed && isStringNotNull(value) ? getDayFormat(value) : <div style ={{ height: UNIT * 1.5 }}/> }
    </Button>
  );
}

class CartModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }
componentDidUpdate(prevProps, prevState) {
  const {
    commande,
    openHours
  } = this.props;
   if (!_.isEqual(prevProps.openHours, openHours) && openHours) {
   const maximumPreparationTime = getMaximumPreparationTime(commande);
   getFirstDay(this, maximumPreparationTime);
}
}
  renderCreditCard = card => {
    const { showPaymentModal } = this.props;
    const { name } = card;
    return (
      <div className="input-group mb-3">
        <input
          type='text'
          className='form-control no-localization dark-modal'
          placeholder={name}
          aria-describedby="button-addon2"
          readOnly
        />
        <div className="input-group-append">
          <Button
            className='btn-outline-secondary label'
            id='button-addon2'
            style={{ borderRadius: `0px ${UNIT / 4}px ${UNIT / 4}px 0px` }}
            onClick={() => {
              showPaymentModal(true);
            }}
          >
            Modifier
          </Button>
        </div>
      </div>
    );
  };

  renderOrderButton = () => {
    const {
      commande,
      userConnected,
      secondaryColor,
      showConnexionModal,
      terminal,
      customerAddress,
      multiOrder,
      orders,
      showMessageModal,
      orderType
    } = this.props;
    const { orderButtonTerminalVertical } = styles(secondaryColor);
    if (!_.isEmpty(commande) && customerAddress) {
      return (
        <Button
          type={PRIMARY}
          className={`btn ${isTerminalVertical() && 'ml-4'}`}
          style={isTerminalVertical() && orderButtonTerminalVertical}
          onClick={() => {
            const maximumPreparationTime = getMaximumPreparationTime(commande);
            getFirstDay(this, maximumPreparationTime);
            if (isWebView()) {
              sendOrderMessenger(this);
            } else if (
              isTerminal() &&
              ((terminal.easel && orderType === ORDER_TYPE_CLICK_AND_SEAT) ||
                (terminal.easelCC && orderType === ORDER_TYPE_CLICK_AND_COLLECT))
            ) {
              store.dispatch(showCartModal(false));
              store.dispatch(showOverlayCover(INPUT_EASEL_NUMBER_CONTENT));
            } else if (isTerminal()) {
              store.dispatch(showCartModal(false));
              store.dispatch(showOverlayCover(CHOOSE_PAYMENT_TYPE_CONTENT));
            } else if (!multiOrder && !_.isEmpty(orders)) {
              showMessageModal(
                `Vous ne pouvez pas valider votre panier car vous avez déjà ${_.size(orders) > 1 ? `${_.size(orders)} commandes` : 'une commande'
                } en cours`
              );
            } else if (userConnected || isQrCode()) {
              this.setState({ choosePayment: true });
            } else {
              showConnexionModal(true);
            }
          }}
        >
          Commander
        </Button>
      );
    }
  };

  renderAddress = () => {
    const { customerAddress } = this.props;
    return (
      <div className="input-group mb-1 no-localization">
        <input
          type='text'
          className='form-control dark-modal label'
          placeholder={getAddressFormatted(customerAddress)}
          aria-describedby="button-addon3"
          readOnly
        />
      </div>
    );
  };

  renderOrderTime = () => {
    const { commande, orderType, updateOrderTime, orderTime, closed } = this.props;
    const { hourSelectValue, selectDay, selectTime } = this.state;
    const { day, isValidDate } = this.state;
    let title = 'Heure de récupération';
    if (orderType === ORDER_TYPE_DELIVERY) {
      title = 'Heure de livraison';
    }
    const maximumPreparationTime = getMaximumPreparationTime(commande);
    const orderTimes = getOrderTimes(day, orderType, maximumPreparationTime);
    const displayDay = moment().isBefore(day)
      return (
        <div className='mb-2'>
          <p className='text-muted mb-1 label'>{title}</p>
          <div className='row pb-2'>
            <div className='col-6 pr-3'>
              <p className='mb-0'>Jour: </p>
              <DatePicker
                selected={displayDay && !selectDay ? null : day.toDate() }
                filterDate={date => {
                  const isDayIncluded = getExcludedOrderDays(
                    date,
                    orderType,
                    maximumPreparationTime
                  );
                  return isDayIncluded;
                }}
                onChange={date => {
                  const newDate = moment(date);
                  const orderTimes = getOrderTimes(newDate, orderType, maximumPreparationTime);
                  updateOrderTime(orderTimes[0]);
                  this.setState({ day: newDate, isValidDate: true, hourSelectValue: 0, selectDay: true });
                }}
                customInput={<CustomDayPickerInput isValidDate={isValidDate} orderTime={orderTime?.value} closed={closed}/>}
                locale={fr}
                maxDate={moment()
                  .add(30, 'd')
                  .toDate()}
                  disabled={closed}
              />
               {!isValidDate && !orderTime && <div className="invalid-feedback-block">Veuillez choisir une date </div> }
            </div>
            <div className="col-6 pl-3">
              <p className="mb-0">Heure: </p>
              <select disabled={(displayDay && !selectDay) || closed} className={`custom-select ${!isValidDate && orderTime && !orderTime.currentSlot && 'is-invalid' } dark-modal`}
               onChange={e => {
                const index = e.target.value;
                const orderTime = orderTimes[index];
                updateOrderTime(orderTime);
                this.setState({ hourSelectValue: index, isValidDate: true, selectTime: true });
                 }}
               value={((!orderTimes[0]?.currentSlot && !selectTime) || closed ? '' : hourSelectValue)}
              >
               <option value='' className='empty-option'></option>
               {orderTimes.map((orderTime, index) => {
               const { value } = orderTime;
               return <option value={index}>{value}</option>;
               })}
              </select>
              {!isValidDate && orderTime && !orderTime.currentSlot && <div className="invalid-feedback-block">Veuillez choisir une heure </div> }
            </div>
          </div>
          {maximumPreparationTime > 0 && (
            <p className="text-warning mb-0">
              {`Votre panier nécessite au moins ${convertHoursToDays(maximumPreparationTime) > 0
                ? `${convertHoursToDays(maximumPreparationTime)} jour(s)`
                : `${maximumPreparationTime} heure(s)`
              } de préparation`}
            </p>
          )}
        </div>
      );

  };
  renderAskPhone = () => {
    const { user, updateProfile } = this.props;
    const { numero } = user;
    const className = `form-control ${this.isPhoneIncorrect || !isStringNotNull(numero) ? 'is-invalid' : ''
    }`;
    return (
      <div className="input-group mb-2">
        <PhoneInput
          value={numero}
          onChange={(phone, country, e, formattedPhone) => {
            updateProfile('numero', formattedPhone);
          }}
          className={`dark-modal ${className}`}
          isPhoneIncorrect={isPhoneIncorrect => {
            this.isPhoneIncorrect = isPhoneIncorrect;
          }}
          getPhoneObj={(obj) => {
          this.phoneObj = obj
          }}
        />
        {(this.isPhoneIncorrect || !isStringNotNull(numero)) && (
          <div className="invalid-feedback-block">Veuillez fournir un telephone valide</div>
        )}
      </div>
    );
  };

  renderAskApartment = () => {
    const { customerAddress, updateAddressAttribute } = this.props;
    const { street2 } = customerAddress;
    return (
      <div className="input-group mb-1">
        <input
          name="street2"
          type="text"
          value={street2}
          placeholder="Nom de l'immeuble/N° d'appartement"
          onChange={e => {
            updateAddressAttribute('street2', e.target.value);
          }}
          className='form-control dark-modal'
        />
      </div>
    );
  };

  renderAskInstructions = () => {
    const { customerAddress, updateAddressAttribute } = this.props;
    const { instruction } = customerAddress;
    return (
      <TextInputCounter
        className="form-control mb-2"
        placeholder="Instructions supplémentaires"
        style={{ resize: 'none' }}
        counterStyle={{ position: 'absolute', right: '5px', bottom: '10px' }}
        value={instruction}
        onChange={e => {
          updateAddressAttribute('instruction', e.target.value);
        }}
      />
    );
  };

  renderPaymentType = () => {
    const { user, showPaymentModal } = this.props;
    const { cards } = user;
    const paymentTypes = getPaymentTypes();
    return paymentTypes.map(paymentType => {
      let className = 'btn btn-block ';
      if (paymentType !== PAYMENT_TYPE_STRIPE) {
        className += 'btn-secondary';
      }
      return (
        <div className="row mb-2">
          <div className="col">
            <Button
              type={`${paymentType === PAYMENT_TYPE_STRIPE ? PRIMARY : SECONDARY}`}
              className={className}
              onClick={async () => {
                const { isUserAnonymous } = this.props;
                const card = getCreditCard(cards);
                if (paymentType === PAYMENT_TYPE_STRIPE && !card) {
                  if (!isUserAnonymous) {
                    showPaymentModal(true);
                  } else {
                    const { stripe, elements } = this.props;
                    this.setState({ loading: true });
                    const card = await addCustomerCard(this, stripe, elements);
                    if (card) {
                      await checkOrder(this, paymentType, card);
                    } else {
                      this.setState({ loading: false });
                    }
                  }
                } else {
                  await checkOrder(this, paymentType, card);
                }
              }}
            >
              {getPaymentTypeLabel(paymentType)}
            </Button>
          </div>
        </div>
      );
    });
  };

  renderBody = () => {
    const { choosePayment, loading } = this.state;
    const { user, orderType, isUserAnonymous, commande, phrases, hideScheduledTime } = this.props;
    const { cards } = user;
    const card = getCreditCard(cards);
    const paymentTypes = getPaymentTypes();
    if (choosePayment) {
      return (
        <Fade>
          <div className="container-fluid">
            {phrases && isStringNotNull(phrases.cartPhrase) && (
              <div className="row mb-2">
                <div className="col text-center">
                  <div className="alert alert-warning mb-0" role="alert">
                    <p className="text-break">{phrases.cartPhrase}</p>
                  </div>
                </div>
              </div>
            )}
            {isUserAnonymous && (
              <>
                <h5 className='text-center mb-3 text-secondary label'>
                  Comment souhaitez-vous payer?
                </h5>
                {paymentTypes.includes(PAYMENT_TYPE_STRIPE) && (
                  <div className="mb-2">
                    <AddPayment />
                  </div>
                )}
              </>
            )}
            {!isUserAnonymous && (
              <>
                {!hideScheduledTime && this.renderOrderTime()}
                {orderType === ORDER_TYPE_DELIVERY && (
                  <div>
                    <div className="row mb-2">
                      <div className="col">
                        <div>
                          <p className='text-muted mb-2 label'>Votre adresse</p>
                          {this.renderAddress()}
                        </div>
                      </div>
                    </div>
                    <div className="row mb-2">
                      <div className="col">{this.renderAskApartment()}</div>
                    </div>
                    <div className="row mb-2">
                      <div className="col">{this.renderAskInstructions()}</div>
                    </div>
                  </div>
                )}
                <div className='row mb-2'>
                  <div className='col'>
                    <p className='text-muted mb-2 label'>Votre numéro</p>
                    {this.renderAskPhone()}
                  </div>
                </div>
              </>
            )}

            <div className="row mb-2">
              <div className="col">
                {card && (
                  <div>
                    <p className='text-muted mb-2 label'>Carte bancaire</p>
                    {this.renderCreditCard(card)}
                  </div>
                )}
              </div>
            </div>
            {loading && (
              <div className="text-center mb-2">
                <Spinner />
              </div>
            )}
            {!loading && <div>{this.renderPaymentType()}</div>}
            <div className="row mb-2">
              <div className="col">
                <img
                  src={SECURE_PAYMENT_STRIPE_IMAGE_URL}
                  alt="stripe secure logo"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </Fade>
      );
    }
    return <Order order={commande} showCart showPromo deleteOption showFees />;
  };

  renderFooter = () => {
    const { loading, choosePayment } = this.state;
    const { showCartModal, commande } = this.props;
    if (_.isEmpty(commande)) {
      return (
        <Button
          type={SECONDARY}
          onClick={() => {
            showCartModal(false);
            this.setState(INITIAL_STATE);
          }}
        >
          Fermer
        </Button>
      );
    }
    if (choosePayment) {
      return (
        <Button type={SECONDARY} onClick={() => this.setState({ choosePayment: false })}>
          Retour
        </Button>
      );
    }
    return (
      <>
        {loading && <Spinner />}
        {!loading && (
          <>
            <Button
              type={SECONDARY}
              style={isTerminalVertical() ? { fontSize: UNIT * 1.25 } : {}}
              onClick={() => {
                showCartModal(false);
                this.setState(INITIAL_STATE);
              }}
            >
              Fermer
            </Button>
            {this.renderOrderButton()}
          </>
        )}
      </>
    );
  };

  render() {
    const { cartModal, showCartModal, order, secondaryColor } = this.props;
    return (
      <>
        <Modal
          onHide={() => {
            showCartModal(false);
            this.setState(INITIAL_STATE);
          }}
          show={cartModal}
          aria-labelledby='contained-modal-title-vcenter'
          centered
          scrollable
          dialogClassName={`${isTerminalVertical() && 'modal-terminal-vertical'}`}
        >
          <ModalBorder
            className='hide-scroll modal-size-fixed'
            color={`${isTerminalVertical() ? secondaryColor : COLOR_LIGHT_GRAY}`}
          >
            <>
              <Modal.Body
                style={!_.isEmpty(order) ? { minHeight: window.innerHeight * 0.8 } : {}}
                className='dark-modal'
              >
                {this.renderBody()}
              </Modal.Body>
              <Modal.Footer className='modal-sticky-footer d-flex justify-content-center dark-modal'>
                {this.renderFooter()}
              </Modal.Footer>
            </>
          </ModalBorder>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = ({
  componentReducer,
  pendingOrderReducer,
  userReducer,
  configurationReducer,
  shopReducer,
  orderReducer
}) => {
  const { userConnected, user, isUserAnonymous } = userReducer;
  const {
    secondaryColor,
    paymentTypes,
    terminal,
    multiOrder,
    webapp,
    configHours
  } = configurationReducer;
  const { cartModal, isMobile } = componentReducer;
  const { orderTime, orders } = orderReducer;
  const { openHours, shopId, closed } = shopReducer;
  const { phrases } = webapp;
  const { commande, orderType, address: customerAddress } = pendingOrderReducer;
  return {
    commande,
    orderType,
    isUserAnonymous,
    userConnected,
    user,
    customerAddress,
    secondaryColor,
    shop: shopReducer,
    shopId,
    cartModal,
    paymentTypes,
    orderTime,
    isMobile,
    openHours,
    terminal,
    multiOrder,
    orders,
    phrases,
    hideScheduledTime: configHours.hideScheduledTime,
    closed
  };
};

export default connect(mapStateToProps, {
  showPaymentModal,
  showCartModal,
  showConnexionModal,
  updateProfile,
  updateAddressAttribute,
  updateOrderTime,
  showMessageModal
})(CartModal);
