import { addPromotionBackend } from '../../api'
import {
  checkAndGetZoneData,
  checkMinBasketPrice,
  errorPromotion,
  getShopData,
  isStringNotNull,
  renderErrorMessage,
  sendCloudWatchLogs
} from '../../utils'
import store from '../../redux/store'
import { showMessageModal } from '../../redux/actions'
import _ from 'lodash'

export const addPromotion = async (component, promoCode, totalPriceOrder) => {
  const minBasketPrice = await checkMinBasketPrice(promoCode, totalPriceOrder)
  try {
    const { user } = store.getState().userReducer
    const { customerId } = user
    component.setState({ loadingPromotion: true })
    if (isStringNotNull(promoCode)) {
      sendCloudWatchLogs(`Adding code promo ${promoCode}`)
      await addPromotionBackend(customerId, promoCode, customerId)
      store.dispatch(showMessageModal(`Votre code promotionnel ${promoCode} a bien été ajouté ${minBasketPrice ? `pour 
      pouvoir profiter de ce code promotionnel votre panier doit être superieur à ${minBasketPrice}€` : ''}`))
      component.setState({ loadingPromotion: false })
    } else {
      store.dispatch(showMessageModal(`Vous devez entrer un code promotionnel.`))
      component.setState({ loadingPromotion: false })
    }
  } catch (error) {

    const errorMessage = String(error)
    component.setState({ loadingPromotion: false })
    const message = renderErrorMessage(errorMessage)
    store.dispatch(showMessageModal(errorPromotion(message)))

    sendCloudWatchLogs(`Error adding promotion ${error}`)
  }
}

export const getUserServicePriceInfoFromShop = (customerAddress, shopId, shops) => {
  if (!shopId || !customerAddress) {
    return null
  }
  const shop = _.find(shops, (shop, id) => id === shopId)
  const { zoneData } = checkAndGetZoneData(customerAddress, shop.deliveryZones, shop.openCities)
  return getShopData(shop, zoneData)?.userServicePriceInfo
}
